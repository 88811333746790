import { Attr, HasMany } from "@temed/vue-jsonapi-orm";
import { ApiResourceWithDates } from "@/models/base/ApiResourceWithDates";
import {Dayjs} from "dayjs";
import {DayjsMeta} from "@/decorators/decorators";


export class Employee extends ApiResourceWithDates {
    static jsonApiType = "employees";

    @Attr() firstName?: string;
    @Attr() lastName?: string;
    @Attr() middleName?: string;
    @Attr() userName?: string;
    @Attr() phone?: string;
    @Attr() email?: string;
    @Attr() telegramIds?: number[];

    @HasMany() comments?: Comment[];

    get fio() {
        const partial = [this.lastName, this.firstName, this.middleName]
        return partial.filter(it => !!it).join(" ")
    }
}