import { Attr, Meta, HasMany, BelongsTo } from "@temed/vue-jsonapi-orm";
import { ApiResourceWithDates } from "@/models/base/ApiResourceWithDates";
import {DayjsMeta} from "@/decorators/decorators";
import {Dayjs} from "dayjs";
import {BookClinic} from "@/models/BookClinic";
import {BookEmployee} from "@/models/BookEmployee";
import {ProfileSyncStatus} from "@/models/types/Types";
export class Profile extends ApiResourceWithDates {
    static jsonApiType = "profiles";

    @Attr() firstName?: string;
    @Attr() lastName?: string;
    @Attr() middleName?: string;
    @Attr() sex?: string;
    @Attr() birthDate?: Dayjs;
    @Attr() phone?: string;
    @Attr() email?: string;
    @Attr() consented?: boolean;
    @Attr() extCode?: number;
    @Attr() code?: string;
    @Attr() extId?: string;
    @Meta() extStatus?: string;
    @DayjsMeta() lastStatusSync?: Dayjs;
    @Meta() isEmployee?: boolean
    @Attr() syncStatus?: ProfileSyncStatus

    @BelongsTo() doctor?: BookEmployee;
    @BelongsTo() clinic?: BookClinic;

    get fio(): string | undefined {
        const fioParts: string[] = []
        if (this.lastName) fioParts.push(this.lastName)
        if (this.firstName) fioParts.push(this.firstName)
        if (this.middleName) fioParts.push(this.middleName)
        if (fioParts.length) {
            return fioParts.join(' ')
        }
        return undefined
    }
}