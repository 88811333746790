import {Attr, HasMany, BelongsTo, Meta} from "@temed/vue-jsonapi-orm";
import { ApiResourceWithDates } from "@/models/base/ApiResourceWithDates";
import { Employee } from "@/models/Employee";
import { Dayjs } from "dayjs";
import { DayjsMeta } from "@/decorators/decorators";

export class BotScaleLog extends ApiResourceWithDates {
    static jsonApiType = "botScaleLogs";

    // meta (read-only)
    @Meta() rowIndex?: number;
    @Meta() logId?: number;
    @Meta() phoneNumber?: string;
    @Meta() code?: string;
    @DayjsMeta() askDate?: string;
    @DayjsMeta() answerDate?: string;
    @Meta() value?: number;
    @Meta() textValue?: string;
    @Meta() isCommand?: boolean;
    @Meta() comment?: string;
    @Meta() additional?: string;
    @Meta() additionalRowIndex?: number;
    @Meta() additionalLogId?: number;
    @DayjsMeta() additionalDate?: string;
}