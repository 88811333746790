
import {Component, Prop, Vue} from "vue-property-decorator";
import {Project} from "@/models";
import {WebTask} from "@/models/WebTask";
import {ResourceCollection} from "@temed/vue-jsonapi-orm";
import WebTaskEditForm from "@/components/webtask/WebTaskEditForm.vue";
import {WebTaskType} from "@/models/types/Types";
import WebTaskTable from "@/components/webtask/WebTaskTable.vue";

@Component({
  computed: {
    WebTaskType() {
      return WebTaskType
    }
  },
  components: {WebTaskTable, WebTaskEditForm}
})
export default class WebTaskTab extends Vue {
  @Prop() private project!: Project;

  private taskToEdit: WebTask | null = null;
  private editMode = false;
  private newTaskType: WebTaskType | null = null;

  private tasks = new ResourceCollection(
      WebTask.api().page(1).perPage(25),
      'wt_tab'
  )

  mounted() {
    //this.loadData()
  }

  loadData() {
    this.tasks.setFilter({ project: this.project.id })
  }

  createTask(taskType: WebTaskType) {
    this.taskToEdit = WebTask.createInstance(taskType, this.project);
    this.editMode = true
    this.newTaskType = taskType
  }

  setTaskToEdit(id: string) {
    //console.log('[ WebTaskTab#selectTaskToEdit ] receive task id to edit', id)
    const taskToEdit = WebTask.fromId(id);
    //console.log('[ WebTaskTab#selectTaskToEdit ] WebTask.fromId -> ', id)
    if (taskToEdit) {
      this.taskToEdit = taskToEdit;
      this.editMode = true;
      this.newTaskType = taskToEdit.taskType ? taskToEdit.taskType : null
    }
  }

  onCreateTask(newTask: WebTask) {
    //console.log('[ WebTaskTab#onCreateTask ] newTask', newTask)
    this.taskToEdit = null;
    this.editMode = false
    this.newTaskType = null;
    //this.loadData()
  }
}
