import { DayjsMeta } from "@/decorators/decorators";
import {Attr, BelongsTo, Meta} from "@temed/vue-jsonapi-orm";
import {ChannelUser} from "@/models/ChannelUser";
import {ApiResource} from "@/models/base/ApiResource";
import {Dayjs} from "dayjs";

export class ChannelUserStateHistory extends ApiResource {
    static jsonApiType = "channelUserStateHistories";

    @BelongsTo() channelUser?: ChannelUser;
    @DayjsMeta() createdDate?: Dayjs;

    @Meta() state?: string;
    @Meta() prevState?: string;
    @Attr() payState?: string;
    @Meta() prevPayState?: string;
}