import { Attr } from "@temed/vue-jsonapi-orm";
import {ApiResourceWithDates} from "@/models/base/ApiResourceWithDates";

export class Assistant extends ApiResourceWithDates {
    static jsonApiType = "assistants";

    @Attr() name?: string
    @Attr() keyName?: string
    @Attr() description?: string
    @Attr() model?: string
    @Attr() identity?: string
    @Attr() task?: string
    @Attr() context?: string
    @Attr() temperature?: number
    @Attr() maxTokens?: number
}