import {Dayjs} from "dayjs";

export interface ChatActivityEvent {
    id: string
    chatId: string
    preferName: string
    actor: Actor
    channelName: string
}

export interface AbstractValue {
    type: string,
    value: any
}

export interface IntegerValue extends AbstractValue {
    value: number
}

export interface StringValue extends AbstractValue {
    value: string
}

export interface TimeValue extends AbstractValue {
    value: string
}

export default interface MappedAnswer {
    step?: string,
    valueName?: string,
    question?: string,
    value: IntegerValue | StringValue | TimeValue
}

export enum Actor {
    USER = "USER",
    USER_ACTION = "USER_ACTION",
    CALLBACK = "CALLBACK",
    BOT = "BOT",
    SYSTEM = "SYSTEM",
    SCHEDULER = "SCHEDULER",
    EMPLOYEE = "EMPLOYEE",
    ASSISTANT = "ASSISTANT"
}

export enum Sex {
    MALE = "MALE",
    FEMALE = "FEMALE"
}

export interface BookClientStatusRecord {
    date: string,
    status: string
}

export interface BookClientStandardRecord {
    date: string,
    name: string
}
export interface BookClientHistoryRecord {
    date: Dayjs,
    status?: string,
    name?: string
}

export enum KeyStatus {
    ACTIVE = "ACTIVE",
    OVERDUE = "OVERDUE",
    REJECTED = "REJECTED",
    BOT_BLOCKED = "BOT_BLOCKED"
}

export interface SimpleUserChannelDto {
    cid: string,
    chid: string,
    name: string,
    active: boolean
}

export interface EnumTranslateType {
    id: string;
    title: string;
}

export enum ProjectState {
    ACTIVE = "ACTIVE",
    SUSPENDED = "SUSPENDED",
    ARCHIVED = "ARCHIVED"
}

export enum BookmarkType {
    BOOKMARK = "BOOKMARK",
    CONVERSATION = "CONVERSATION"
}

export const projectStates: Map<string, string> = new Map<ProjectState, string>([
    [ProjectState.ACTIVE, "Активный"],
    [ProjectState.SUSPENDED, "Приостановлен"],
    [ProjectState.ARCHIVED, "Архивный"],
])

export const projectStateTranslate: Array<EnumTranslateType> = [
    { id: ProjectState.ACTIVE, title: "Активный"},
    { id: ProjectState.SUSPENDED, title: "Приостановлен"},
    { id: ProjectState.ARCHIVED, title: "Архивный"},
]

export enum ChannelType {
    TELEGRAM = "TELEGRAM",
    WHATSAPP = "WHATSAPP",
    WECHAT = "WECHAT"
}

export type FaIconSize = "2xs" | "xs" | "sm" | "lg" | "xl" | "2xl"

/**
 * { Java enum to TypeScript enum generation }
 * Export enum: ru.temed.tmp.demo.entity.enums.WebTaskType
 * */
export enum WebTaskType {
    TEST = 'TEST',
    USER_IMPORT = 'USER_IMPORT',
    USER_CHECK = 'USER_CHECK',
    USER_PROFILE_SYNC = 'USER_PROFILE_SYNC',
}

export enum ProfileSyncStatus {
    TO_SYNC = "TO_SYNC",
    SYNCED = "SYNCED",
    UPDATED = "UPDATED",
    NOT_FOUND = "NOT_FOUND",
    ERROR = "ERROR",
    CONN_ERROR = "CONN_ERROR",
}

/**
 * { Java enum to TypeScript enum generation }
 * Export enum: ru.temed.tmp.demo.entity.enums.WebTaskState
 * */
export enum WebTaskState {
    CREATED = 'CREATED',
    IN_PROGRESS = 'IN_PROGRESS',
    STOPPED = 'STOPPED',
    CANCELED = 'CANCELED',
    DONE = 'DONE',
    ERROR = 'ERROR',
}

/**
 * { Java class to TypeScript interface generation }
 * Export class: ru.temed.tmp.demo.entity.embedded.task.AbstractTaskDescriptor
 * */
export interface AbstractTaskDescriptor {
    type: WebTaskType
    channelName: string | null
    channelId: string | null
}

/**
 * { Java class to TypeScript interface generation }
 * Export class: ru.temed.tmp.demo.entity.embedded.task.AbstractTaskData
 * */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface AbstractTaskData {
    type: WebTaskType
    status: string
    progress: number
    totalElements: number
    completeElements: number | null
    duration: string
    message: WebTaskEvent | null
}

export interface TestTaskData extends  AbstractTaskData {
    type: WebTaskType.TEST
}
export interface ImportTaskData extends  AbstractTaskData {
    type: WebTaskType.USER_IMPORT,
    skippedLines: number
    linesWithErrors: number
    createdUsers: number
    existedUsers: number
    savedUsers: number
    createdChannelUsers: number
    existedChannelUsers: number
    savedChannelUsers: number
    createdProfiles: number
    existedProfiles: number
    savedProfiles: number
    createdPlanTime: number
    existedPlanTime: number
    savedPlanTime: number
    createdChannelUserPlans: number
    existedChannelUserPlans: number
    savedChannelUserPlans: number
    migratedUsersCount: number
    migratedEmptyUsers: number
    migratedRecordsCount: number
    createdChats: number
    existedChats: number
    savedChats: number
}

export interface WebTaskEvent {
    time: Dayjs /* поле времени устанавливать на клиенте. сервер не присылает отметки времени */
    level: EventLevel
    message: string,
    url: string
}

export enum EventLevel {
    INFO = "INFO",
    WARNING = "WARNING",
    ERROR = "ERROR"
}


export interface WebTaskSocketEvent {
   progress: number
   duration: string
   data: AbstractTaskData | ImportTaskData | TestTaskData | null;
   messages:  WebTaskEvent[]
}
