import axiosInstance from "@/axios";
import { ApiResourceBase } from "@temed/vue-jsonapi-orm";

export class ApiResource extends ApiResourceBase {
    static apiPath = '/api'
    static axios = axiosInstance
    static convertSnakeCase = false;


    /* откат изменений модели */
    $_rollbackChanges() {
        /* сброс значения аттрибутов на исходное */
        const attrs = Object.keys(this.changedAttributes);
        attrs.forEach(attributeName => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this[attributeName] = this.$getOriginal(attributeName);
            delete this.changedAttributes[attributeName]
        })
        /* сброс значения релятивных полей на исходное */
        const relations = Object.keys(this.changedRelationships);
        relations.forEach(relationshipsKey => {
            const cachedItem = this.relationsCache[relationshipsKey]
            if (cachedItem) {
                this.relations[relationshipsKey] = cachedItem;
                if (Array.isArray(cachedItem)) {
                    this.relationships[relationshipsKey].data = cachedItem.map(item => {
                        return (item as ApiResourceBase).$toIdentifier()
                    })
                }
                else {
                    this.relationships[relationshipsKey].data = (cachedItem as ApiResourceBase).$toIdentifier();
                }
            }
            else {
                this.relations[relationshipsKey] = null;
                this.relationships[relationshipsKey].data = null;
            }
            delete this.changedRelationships[relationshipsKey];
        })
    }


}