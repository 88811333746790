
import {Component, Emit, Prop, Vue} from "vue-property-decorator";
import {Channel} from "@/models";
import ChannelEditForm from "@/components/channel/ChannelEditForm.vue";
import QrcodeVue from 'qrcode.vue'
import ChannelUserTable from "@/components/channeluser/ChannelUserTable.vue";
import {StompService} from "@/services/StompService";
import { v4 } from 'uuid';

@Component({
  components: {ChannelUserTable, ChannelEditForm, QrcodeVue}
})
export default class ChannelCard extends Vue {
  @Prop() channel!: Channel
  @Prop() private editMode!: boolean
  private qrSourceName = ""

  private taskId: string | null = null;
  private taskProgress: number | null = null;

  private socket: StompService | null = null

  mounted() {
    this.socket = this.$stompClient
    //console.log('[ ChannelCard#mount ] socket:', this.socket)
  }

  save() {
    this.channel.save()
  }

  get connAttempt() {
    return this.$stompClient.attempt
  }
  toggleChannelRegistration() {
    if (!this.channel) return;
    if (this.channel.registered) {
      Channel.api()
          .request(`/${this.channel.id}/register`, 'DELETE')
          .then(() => Channel.api().with(['defaultPlan', 'defaultAssistant']).find(this.channel.id))
    }
    else {
      Channel.api()
          .request(`/${this.channel.id}/register`, 'PUT')
          .then(() => Channel.api().with(['defaultPlan', 'defaultAssistant']).find(this.channel.id))
    }
    //console.log("need send PUT to server")
  }

  someToast() {
    this.$bvToast.toast("it`s a toast?", {
      // title: 'API Error',
      variant: 'warning',
      solid: true,
      noCloseButton: true
    })
  }

  toggleEditMode(isEdit: boolean) {
    this.$emit('update:editMode', isEdit)
  }

  get qrcodeLink() {
    return Channel.buildTelegramInviteBase64Link(this.channel, this.qrSourceName)
  }

  createCheckTask() {
    this.taskId = v4();
    this.$stompClient.$subscribe({
      topicName: `/task/${this.taskId}`,
      subscriptionName: `task-${this.taskId}`,
      callback: this.onTaskProgress
    })
    Channel.api().request(`/${this.channel.id}/action/check/${this.taskId}`, 'PUT')
  }

  onTaskProgress(data: any) {
    this.taskProgress = data.progress
    if (data.status === "complete") {
      const taskSubscription = this.$stompClient.getSubscription(`task-${this.taskId}`)
      if (taskSubscription) {
        //console.log('unsubscribe from:', taskSubscription)
        taskSubscription.subscription?.unsubscribe();
      }
    }
  }

  @Emit()
  onCreate(newChannel: Channel) {
    //console.log("[ ChannelCard::onCreate ] emit onCreate channel: ", newChannel)
    return newChannel;
  }
  @Emit()
  onCreateCancel(newChannel: Channel) {
    //console.log("[ ChannelCard::onCreateCancel ] emit destroy channel: ", newChannel)
    return newChannel;
  }

  @Emit()
  onDelete() {
    //console.log("[ ChannelCard::onDelete ] emit after destroy [this] channel: ", this.channel)
    return this.channel.destroy().then(result => {
      //console.log("[ ChannelCard::onDelete ] result: ", result)
      return result
    });
  }

}
