import { Attr, HasMany, BelongsTo } from "@temed/vue-jsonapi-orm";
import { ApiResourceWithDates } from "@/models/base/ApiResourceWithDates";
import { Employee } from "@/models/Employee";

export class Comment extends ApiResourceWithDates {
    static jsonApiType = "comments";

    @Attr() comment?: string;

    @BelongsTo() parent?: Comment;
    @BelongsTo() author?: Employee;
    @HasMany() replies?: Comment[];

}