
import {Component, Prop, Vue} from "vue-property-decorator";
import {ImportTask} from "@/models/embedded/task/ImportTask";
import {ResourceCollection} from "@temed/vue-jsonapi-orm";
import {ChatPlan} from "@/models";

@Component
export default class ImportTaskDescriptor extends Vue {
  @Prop() private task!: ImportTask;

  private chatPlans = new ResourceCollection<ChatPlan>(
      ChatPlan.api().page(1).perPage(100)
  )
  mounted() {
    //console.log("[ ImportTaskDescriptor ] task: ", this.task)
    this.chatPlans.requestItems()
  }

  onChatPlanSelect(id: string | null) {
    if (id) {
      const chatPlan = ChatPlan.fromId(id)
      this.task.chatPlanName = chatPlan.keyName ? chatPlan.keyName : null
    }
    else {
      this.task.chatPlanId = null;
      this.task.chatPlanName = null;
    }
  }
}
