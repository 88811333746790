
import { Component, Vue } from "vue-property-decorator";
import {ResourceCollection} from "@temed/vue-jsonapi-orm";
import {Project} from "@/models/Project";
import { ProjectState, projectStates } from "@/models/types/Types";

@Component({
  computed: {
    projectStates() {
      return projectStates
    },
    ProjectState() {
      return ProjectState
    }
  }
})
export default class ProjectsView extends Vue {
  projects = new ResourceCollection(
      Project.api()
          .with(['owner', 'channels'])
          .select({'project': ['id', 'name']})
          .perPage(25)
          .page(1)
  );

  mounted() {
    //console.log("[ ProjectsView::mounted ]", this.projects.queryBuilder.select({'project': ['id', 'name', 'description']}))
    this.projects.requestItems();
  }

  tableFields = [
    {
      key: 'name',
      label: 'Наименование',
      sortable: true
    },
    {
      key: 'description',
      label: 'Описание',
      sortable: true
    },
  ];
  get tableItems() {
    if (!this.projects.items.length) {
      return [];
    }
    /* пересборка ресурсов в массив для вывода в компонент таблицы */
    return this.projects.items.map(project => {
      return {
        'id': project.id,
        'name': project.name,
        'description': project.description,
        'createdDate': project.createdDate?.format('L LT'),
        'modifiedDate': project.modifiedDate?.format('L LT'),
        'accessLevel': project.accessLevel,
        'owner': project.owner?.lastName + ' ' + project.owner?.firstName + ' (' + project.owner?.email + ')',
        'ownerId': project.owner?.id
      }
    })
  }

  toProject(project: Project) {
    //console.log("to project: ", project);
    this.$router.push('/projects/' + project.id)
  }

}
