
import {Vue, Component, Emit, Prop, Watch} from 'vue-property-decorator';
import {StompService} from "@/services/StompService";
import {ResourceCollection} from "@temed/vue-jsonapi-orm";
import {Channel, Chat} from "@/models";
import ChannelUserProfileItem from "@/components/channeluser/ChannelUserProfileItem.vue";
import {byPropertiesOf} from "@/types/sorter";
import {Dictionary} from "vue-router/types/router";
import {RawLocation} from "vue-router";
import {StompSubscription} from "@/types/StompTypes";
import {ChatActivityEvent} from "@/models/types/Types";

@Component({
  components: {ChannelUserProfileItem}
})
export default class ChatList2 extends Vue {
  private channelId: string | null = null
  @Prop() externalSelectedChat!: Chat
  @Prop() searchText!: string
  @Prop() onlyAttention!: boolean
  @Prop() channelName!: string

  private channel: Channel | null = null

  private socket: StompService | null = null

  private chats: ResourceCollection<Chat> | null = null

  private selectedChat: Chat | null = null
  private loading = false
  private hasLoad = false
  private scrollHeight = 0

  private subscription: StompSubscription | null = null

  mounted() {
    this.socket = this.$stompClient
    //console.log('[ ChatList#mounted ] socket', this.socket)
    //console.log('[ ChatList#mounted ] channelId', this.channelId, 'channel: ', this.channel, 'channelName', this.channelName)
    //console.log('[ ChatList#mounted ] channelName', this.channelName)
    // this.loadData().then(() => {
    //   this.subscribe()
    // }).finally(() => { this.hasLoad = true })
    this.loadAndSubscribe()
    this.$root.$on('bookmarkDeleted', (chat: Chat) => {
      this.onBookmarkDeleted(chat)
    })
    this.$root.$on('conversationDeleted', (chat: Chat) => {
      this.onBookmarkDeleted(chat)
    })
  }

  loadAndSubscribe() {
    //console.log('[ ChatList#loadAndSubscribe ] channelId')
    this.unsubscribe()
    const loadPromise = this.loadData()
    if (loadPromise) {
      this.loading = true
      loadPromise.then(() => {
        this.subscribe()
      }).finally(() => {
        this.hasLoad = true
        this.loading = false
      })
    }
    // else {
    //   this.loading = false
    // }
  }

  private loadData() {

    if (['bookmarks', 'conversations'].includes(this.channelName)) {
      //console.log('[ ChatList2#loadData ] { load static filter for bookmarks or conversations} => ', this.buildFilter())
      this.chats = this.buildResourceCollection(null, this.searchText, this.buildFilter());
      return this.chats.requestItems()
    }
    else if (this.channelName) {
      //console.log('[ ChatList::loadData ] load channel page by channelName: ' + this.channelName);
      const tmpChannels = new ResourceCollection(
          Channel.api().filter({'userName': this.channelName}),
          `tmp-channels-by-name-${this.channelName}`
      )
      return tmpChannels.requestItems().then(items => {
        //console.log('[ ChatList2::mounted ] load channel page by channelName | result: ', items);
        if (items && items.length) {
          this.channel = items[0]
          //console.log('[ ChatList2#loadData ] channelId: ', this.channel.id, 'filter:', this.buildFilter())
          this.chats = this.buildResourceCollection(this.channel.id, this.searchText, this.buildFilter());
          return this.chats.requestItems()
        }
      })
    }
    else {
      //console.log('[ ChatList#loadData ] default: ', this.channelId, 'filter:', this.buildFilter())
      this.chats = this.buildResourceCollection(this.channelId, this.searchText, this.buildFilter());
      return this.chats.requestItems()
    }
  }

  onBookmarkDeleted(chat: Chat) {
    //console.log('{ ChatList#onBookmarkDeleted } chat: ', chat)
    if (['bookmarks', 'conversations'].includes(this.channelName)) {
      this.loading = true
      this.chats?.requestItemsWithCleanup().finally(() => {
        this.loading = false
      })
    }
  }

  @Watch("channelName")
  onChannelNameChange() {
    //console.log('[ ChatList#onChannelNameChange ] channelName: ', this.channelName)
    /* reset channel */
    this.channel = null
    this.loadAndSubscribe()
  }
  @Watch("searchText")
  onSearchTextChange() {
    //console.log('[ ChatList#onSearchTextChange ] searchText: ', this.searchText)
    this.reloadWithFilters()
  }

  @Watch("onlyAttention")
  onOnlyAttentionChange() {
    //console.log('[ ChatList#onOnlyAttentionChange ] attention flag: ', this.onlyAttention)
    this.reloadWithFilters()
  }

  reloadWithFilters() {
    const filter = this.buildFilter()
    //console.log('[ ChatList#reloadWithFilters ] filter: ', filter)
    if (this.chats) {
      this.loading = true
      if (this.chats.pagination) {
        this.chats.pagination = {
          ...this.chats.pagination,
          current_page: 1
        }
      }
      this.chats.setFilterWithCleanup(filter).finally(() => this.loading = false)
    }
  }

  private buildFilter(): Dictionary<any> {
    let filter: Dictionary<any> = {}
    if (this.channel?.id) {
      filter['channel'] = this.channel.id
    }
    if (this.searchText) {
      filter['search'] = this.searchText
    }
    if (this.onlyAttention) {
      filter['attention'] = true
    }
    if (this.channelName === 'bookmarks') {
      filter['bookmark'] = true
    }
    if (this.channelName === 'conversations') {
      filter['conversation'] = true
    }
    return filter
  }

  private buildResourceCollection(channelId: string | null, searchText: string | null, filter: Dictionary<any> = {}): ResourceCollection<Chat> {
    const namePrefix = this.channelName ? `infinity-by-channel-${this.channelName}` : `infinity-all-chats`;
    const collectionName = searchText ? `${namePrefix}-${searchText}` : namePrefix;
    //console.log('[ ChatList#buildResourceCollection ]: ', { channelId, searchText }, 'cn: ' + collectionName, 'filter: ', filter)
    return new ResourceCollection<Chat> (
        Chat.api()
            .page(1)
            .perPage(15)
            .filter(filter)
            .with(['channel', 'channelUser', 'latestChatMessage', 'bookmark', 'conversation', 'channelUserPlans'])
            .orderBy('modifiedDate', "desc"),
        collectionName,
        true
    )
  }

  private getChatStateClass(chat: Chat): string {
    if (chat && chat.channelUser && chat.channelUser.state) {
      if (chat.channelUser.state === 'ACTIVE') {
        return `chat-user-state-${chat.channelUser.state.toLowerCase()}-${chat.lastActor?.toLowerCase()}`
      }
      return `chat-user-state-${chat.channelUser.state.toLowerCase()}`
    }
    return `chat-user-state-unknown`
  }

  get sortedItems(): Chat[] {
    if (this.chats) {
      return this.chats.items.sort(byPropertiesOf<Chat>(['-modifiedDate']))
    }
    return []
  }
  subscribe() {
    this.subscription = this.$stompClient.$subscribe({
      topicName: `/chat/activity`,
      subscriptionName: `chat-activity`,
      callback: (data) => this.onEventReceive(data)
    })
    //console.log('[ ChatList#subscribe ] subscribed to:', `/chat/activity`, this.subscription)
  }

  unsubscribe() {
    if (this.subscription) {
      //console.log('[ ChatMessages#unsubscribe ] from: ', this.subscription)
      this.$stompClient.$unsubscribe(this.subscription)
      this.subscription = null
    }
  }

  onEventReceive(data: ChatActivityEvent) {
    //console.warn('[ ChatList#onEventReceive ] 1. socket event:', data)
    // this.loadData()
    if (data && data.chatId && data.actor && this.chats/* && data.actor === 'USER'*/) {
      Chat.api()
          .with(['channel', 'channelUser', 'latestChatMessage', 'bookmark', 'conversation', 'channelUserPlans'])
          .find(data.chatId).then(chat => {
            //console.log('[ ChatList#onEventReceive ] load new? ', chat);
            //console.log('[ ChatList#onEventReceive ] 2. toast? ', (chat.attention && !!chat.latestChatMessage));

            if (chat.attention && chat.latestChatMessage) {
              const chatLocation: RawLocation = { params: {chatId: chat.id} }
              if (this.$route.name) {
                if (['allChats', 'allChatsChat'].includes(this.$route.name)) {
                  chatLocation.name = 'allChatsChat'
                }
                else if (['chatsByChannel', 'chatsByChannelChat'].includes(this.$route.name)) {
                  chatLocation.name = 'chatsByChannelChat'
                }
              }

              const sound = new Audio('/sound/bell.mp3');
              sound.play().then(() => {
                // no action
              }).catch((err) => {
                //console.warn('play sound error: ', err)
              })


              //console.log('[ ChatList#onEventReceive ] 4. check and send web notify >> ');
              /* Notification API */
              Notification.requestPermission((state) => {
                //console.warn('{ requestPermission }', state)
                if (state === 'granted') {
                  const nid = data.id

                  // let title = `${chat.preferName} [${data.channelName}`
                  // if (chat.isBookmarked) {
                  //   title += ' | закладки]'
                  // }
                  // else if (chat.isConversated) {
                  //   title += ' | переписки]'
                  // }
                  // else {
                  //   title += ']'
                  // }

                  const webNotify = new Notification(data.preferName, {
                    tag: nid,
                    body: chat.latestChatMessage?.text,
                    icon: window.location.origin + '/img/chat-plant-logo-512.png',
                    data: {
                      ...data,
                      isBookmarked: chat.isBookmarked,
                      isConversated: chat.isConversated
                    },
                    //requireInteraction: true
                  })
                  webNotify.onclick = this.onNotifyClick

                  //console.log('{ webNotify } ', webNotify)
                }
              })
            }

            if (this.chats) {
              //console.log('[ ChatList#onEventReceive ] run syncWithStore()...');
              //this.chats.syncWithStore();

              //chat.channelUser?.load('user')
              //chat.channelUser?.load('profile')
            }
            // this.chats.initFromStore()
          })
    }
  }

  /**
   * callback по нажатию на всплывающее сообщение нотификации
   * */
  onNotifyClick(event: Event) {
    window.focus()
    const { target } = event
    // const notifyTarget = target as Notification
    console.log(' { onNotifyClick }', event)
    if (target instanceof Notification) {
      const notify = target as Notification
      const { chatId, channelName, isConversated, isBookmarked } = notify.data
      if (chatId && channelName && !this.$route.fullPath.includes(chatId)) {
        const targetChannel = isBookmarked ? 'bookmarks' : isConversated ? 'conversations' : channelName
        this.$router.push({ name: 'chatsByChannelChat', params: { chatId: chatId, channelName: targetChannel }})
      }
      if (this.$route.fullPath.includes(chatId)) {
        //console.log('{ route target }', chatId, 'we are here')
      }
    }
    else {
      console.warn(' { onNotifyClick | wrong instance }', event)
    }
  }

  isSelected(chat: Chat): boolean {
    return (this.selectedChat && this.selectedChat.id === chat.id) || (this.externalSelectedChat && this.externalSelectedChat.id === chat.id)
  }
  @Emit()
  onChatSelect(chat: Chat): Chat {
    //console.log('[ ChatList#onChatSelect ] emit: ', chat)
    this.selectedChat = chat
    return this.selectedChat
  }

  handleScroll(event: any) {
    const { scrollTop, offsetHeight, scrollHeight } = event.currentTarget
    // if (!this.loading) {
    //   this.scrollHeight = scrollHeight
    // }
    const currentPos = scrollTop + offsetHeight;
    if (this.chats && currentPos > scrollHeight && !this.loading && !this.chats.$isLastPage) {
      this.loading = true
      this.chats.nextPage().finally(() => {
        this.loading = false
        // this.scrollHeight = scrollHeight
      })
    }
  }
}
