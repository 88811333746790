import { Meta, BelongsTo } from "@temed/vue-jsonapi-orm";
import { ApiResourceWithDates } from "@/models/base/ApiResourceWithDates";
import { Attachment } from "@/models/Attachment";
import { Channel } from "@/models/Channel";
export class ChannelAttachment extends ApiResourceWithDates {
    static jsonApiType = "channelAttachments";

    @Meta() telegramFileId?: string;
    @Meta() telegramFileUniqueId?: string;
    @BelongsTo() channel?: Channel[];
    @BelongsTo() attachment?: Attachment;

}