import { Attr, Meta, HasMany, BelongsTo } from "@temed/vue-jsonapi-orm";
import {Employee} from "@/models/Employee";
import {Channel} from "@/models/Channel";
import {ApiResource} from "@/models/base/ApiResource";
import {DayjsMeta} from "@/decorators/decorators";
import {Chat} from "@/models/Chat";
import {Dayjs} from "dayjs";
import {Actor} from "@/models/types/Types";
export class ChatStateHistory extends ApiResource {
    static jsonApiType = "chatStateHistories";

    @BelongsTo() chat?: Chat;
    @DayjsMeta() createdDate?: Dayjs;
    @Attr() state?: string;
    @Attr() prevState?: string;
    @Attr() returnTo?: string;
    @Attr() actor?: Actor;
    @Attr() blocked?: boolean;
    @Attr() active?: boolean;

}