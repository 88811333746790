
import {Component, Watch} from "vue-property-decorator";
import {Vue} from "vue-property-decorator";
import {Project} from "@/models";
import {Route} from "vue-router";
import ChannelsTab from "@/components/channel/ChannelsTab.vue";
import WebTaskTab from "@/components/webtask/WebTaskTab.vue";

@Component({
  components: {WebTaskTab, ChannelsTab}
})
export default class ProjectCard extends Vue {

  private project: Project | null = null;
  private modelLoading = false;
  private currentTab?: string
  created() {
    //console.log("[ ProjectCard::created ] for: " + this.projectId, "hash="+this.$route.hash)
    this.applyRouteHashToTab();
    Project.api().find(this.projectId)
        .then(project => {
          this.project = project;
        })
        .finally(() => this.modelLoading = false);
  }
  mounted() {
    //console.log("[ ProjectCard::mounted ] for: " + this.projectId, "hash="+this.$route.hash)
  }

  isCurrentTab(tabName: string): boolean {
    if (!this.currentTab && tabName === 'index') {
      return true;
    }
    return tabName === this.currentTab;
  }
  onTabSelect(tabName: string | undefined) {
    if (tabName === this.currentTab) return;
    if (tabName === 'index' && this.currentTab) {
      this.$router.push(this.$route.path)
    }
    else if (tabName && tabName !== this.currentTab) {
      this.$router.push(this.$route.path + '#' + tabName)
    }
  }
  applyRouteHashToTab() {
    if (this.$route.hash && this.$route.hash !== this.currentTab) {
      this.currentTab = this.$route.hash.replace("#", "");
    }
    else {
      this.currentTab = undefined;
    }
  }
  @Watch("$route")
  onHashChange(to: Route) {
    this.currentTab = to.hash.replace("#", "");
  }
  loadRelations() {
    if (this.project == null) return;
    this.project.load('owner');
    this.project.load('channels').then(channels => {
      //console.log("channels: ", channels)
    });
  }
  get isLoading() {
    if (this.project != null) {
      return this.project.$isLoading;
    }
    return this.modelLoading;
  }

  get projectId() {
    return this.$route.params.projectId;
  }
}
