import Vue from 'vue'
import Vuex from 'vuex'
import { jsonApiVuexPlugin } from '@temed/vue-jsonapi-orm'
import {
    Assistant,
    Attachment, BookClient, BookClinic, BookEmployee,
    BotScaleLog,
    Channel,
    ChannelUser, ChannelUserPlan,
    ChannelUserStateHistory,
    Chat,
    ChatPlan,
    ChatStateHistory,
    ChatMessage,
    Comment,
    Employee,
    EmployeeBookmark,
    Profile,
    Project,
    User,
    UserAnswer,
    WebTask
} from "@/models";

const resources = [
    Assistant,
    User, Comment, Employee, EmployeeBookmark,
    BotScaleLog, UserAnswer,
    BookClinic, BookEmployee, BookClient,
    Project, Channel, Chat, ChatPlan, ChatStateHistory,
    ChannelUser, ChannelUserStateHistory, Profile,
    ChannelUserPlan,
    Attachment,
    ChatMessage,
    WebTask
]

Vue.use(Vuex)

export default new Vuex.Store({
    plugins: [ jsonApiVuexPlugin(resources) ]
})
