import { Meta, Attr, HasMany, BelongsTo } from "@temed/vue-jsonapi-orm";
import { ApiResourceWithDates } from "@/models/base/ApiResourceWithDates";
import {ChannelUser} from "@/models/ChannelUser";
import {Channel} from "@/models/Channel";
import {ChatStateHistory} from "@/models/ChatStateHistory";
import {TextMessageDto} from "@/types/dtoTypes";
import {AxiosResponse} from "axios";
import {ChatMessage} from "@/models/ChatMessage";
import {EmployeeBookmark} from "@/models/EmployeeBookmark";
import {ChannelUserPlan} from "@/models/ChannelUserPlan";
import {Assistant} from "@/models/Assistant";
export class Chat extends ApiResourceWithDates {
    static jsonApiType = "chats";

    @BelongsTo() channel?: Channel;
    @BelongsTo() channelUser?: ChannelUser;
    @Meta() chatType?: string;
    @Meta() chatId?: number;
    @Meta() blocked?: boolean;
    @Meta() active?: boolean;
    @Meta() lastActor?: string;
    @Attr() stateCode?: string;
    @Meta() prevStateCode?: string;
    @Meta() returnToCode?: string;
    @HasMany() stateHistory?: ChatStateHistory[];
    @Meta() lastMessageId?: number;
    @Meta() timeoutText?: string;
    @Attr() timeout?: number;

    @Meta() sessionId?: string;
    @Meta() sessionStart?: string;
    @Attr() timeoutAction?: string;
    @Meta() title?: string;
    @Meta() isForum?: boolean;

    @Attr() attention?: boolean
    @BelongsTo() latestChatMessage?: ChatMessage

    @HasMany() bookmark?: EmployeeBookmark[]
    @HasMany() conversation?: EmployeeBookmark[]
    @HasMany() channelUserPlans?: ChannelUserPlan[]

    @BelongsTo() assistant?: Assistant

    get isBookmarked(): boolean {
        return this.bookmark?.length === 1
        //return false
    }

    get isConversated() {
        return this.conversation?.length === 1
    }

    /**
     * Выборка наименования чата по доступным именам пользователя согласно приоритету:
     * - ФИО из профиля
     * - ФИ из пользователя телеграм
     * - никнейм из пользователя телеграм
     * - наименование чата (применимо к группам и каналам - todo проверить)
     * - код чата (на крайний случай, если все пустое)
     * */
    get preferName(): string {
        let preferName
        if (this.channelUser) {
            // if (this.channelUser.profile && this.channelUser.profile.fio) {
            //     preferName = this.channelUser.profile.fio
            // }
            // if (this.channelUser.user) {
            //     preferName = this.channelUser.user.preferName
            // }
            preferName = this.channelUser.preferName
            //console.info('chat.preferName: ', preferName)
        }
        if (preferName) {
            return preferName
        }
        else if (this.title) {
            return this.title
        }
        return this.id
    }


    static async sendMessage(chat: Chat, message: TextMessageDto): Promise<AxiosResponse<any, any>> {
        const api = Chat.api()

        return api.request(`${chat.id}/message`, 'PUT', { data: message })
    }

    static async createBookmark(chat: Chat) {
        const api = Chat.api()
        return api.request(`${chat.id}/bookmark`, 'PUT')
    }
    static async removeBookmark(chat: Chat) {
        const api = Chat.api()
        return api.request(`${chat.id}/bookmark`, 'DELETE')
    }
    static async removeConversationMark(chat: Chat) {
        const api = Chat.api()
        return api.request(`${chat.id}/conversation`, 'DELETE')
    }
}