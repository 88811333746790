import { render, staticRenderFns } from "./TParamFlexDisplay.vue?vue&type=template&id=31a4ac59&scoped=true&"
import script from "./TParamFlexDisplay.vue?vue&type=script&lang=ts&"
export * from "./TParamFlexDisplay.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31a4ac59",
  null
  
)

export default component.exports