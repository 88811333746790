
import { Component, Vue } from "vue-property-decorator";
import {ResourceCollection} from "@temed/vue-jsonapi-orm";
import {ChannelUser} from "@/models/ChannelUser";
import {User} from "@/models/User";

@Component
export default class ChannelUsersView extends Vue {

  channelUsers = new ResourceCollection(
      ChannelUser.api()
          .with(["user", "stateHistory"])
          .perPage(25)
          .page(1)
  );

  mounted() {
    //console.log("[ ChannelUsersView::mounted ]")
    this.channelUsers.requestItems().then(channelUsers => {
      channelUsers.forEach(channelUser => {
        channelUser.load('user', ['profilePhoto']);
      })
    });
  }

  attachmentsByUser(user: User) {
    let attachments = new ResourceCollection(user.relationApi('photos'), 'user-photos-collection');
    attachments.setFilter({ 'user': user.id });
  }
}
