
import {Vue, Component, Emit} from 'vue-property-decorator';
import {Assistant} from "@/models";
import AssistantForm from "@/components/assistant/AssistantForm.vue";

@Component({
  components: {AssistantForm}
})
export default class AssistantCard extends Vue {
  private assistant: Assistant | null = null
  private loading = false
  private isEditMode = false

  mounted() {
    this.init()
  }

  init() {
    const { assistantId } = this.$route.params

    if (assistantId === 'create') {
      this.isEditMode = true;
      this.assistant = new Assistant();
      return;
    }

    if ( !assistantId ) return;

    try {
      this.assistant = Assistant.fromId(assistantId)
      this.emitName(this.assistant.name)
    }
    catch (err) {
      this.loading = true
      Assistant.api()
          .find(assistantId)
          .then(assistant => {
            this.assistant = assistant
            this.emitName(this.assistant.name)
          })
          .finally(() => this.loading = false)
    }
  }

  @Emit('childOpen')
  emitName(name: string | undefined) {
    return name ? name : 'Имя не указано'
  }
}
