import dayjs, {Dayjs} from "dayjs";
import IChartIntDataDto from "@/dto/IChartIntDataDto";

export default class ChartNumberDataItem {
    date: Date;
    _date: Dayjs;
    value: number | null;


    constructor(date: dayjs.Dayjs, value: number | null) {
        this.date = date.toDate();
        this._date = date;
        this.value = value;
    }

    static of(dto: IChartIntDataDto):ChartNumberDataItem {
        return new ChartNumberDataItem(dayjs(dto.date), dto.value);
    }
}