
import {Vue, Component, Emit, Prop, Watch} from 'vue-property-decorator';
import {StompService} from "@/services/StompService";
import {ResourceCollection} from "@temed/vue-jsonapi-orm";
import {Chat} from "@/models";
import ChannelUserProfileItem from "@/components/channeluser/ChannelUserProfileItem.vue";
import {byPropertiesOf} from "@/types/sorter";
import {Dictionary} from "vue-router/types/router";
import {RawLocation} from "vue-router";

@Component({
  components: {ChannelUserProfileItem}
})
export default class ChatList extends Vue {
  @Prop() channelId!: string
  @Prop() externalSelectedChat!: Chat
  @Prop() searchText!: string
  @Prop() onlyAttention!: boolean
  private socket: StompService | null = null

  private chats: ResourceCollection<Chat> | null = null

  private selectedChat: Chat | null = null
  private loading = false
  private hasLoad = false
  private scrollHeight = 0


  mounted() {
    this.socket = this.$stompClient
    //console.log('[ ChatList#mounted ] socket', this.socket)
    this.loadData().then(() => {
      this.subscribe()
    }).finally(() => { this.hasLoad = true })
  }

  private loadData() {
    //console.log('[ ChatList#loadData ] channelId: ', this.channelId)
    this.chats = this.buildResourceCollection(this.channelId, this.searchText, this.buildFilter());
    return this.chats.requestItems()
  }

  @Watch("searchText")
  onSearchTextChange() {
    //console.log('[ ChatList#onSearchTextChange ] searchText: ', this.searchText)
    this.reloadWithFilters()
  }


  @Watch("onlyAttention")
  onOnlyAttentionChange() {
    //console.log('[ ChatList#onOnlyAttentionChange ] attention flag: ', this.onlyAttention)
    this.reloadWithFilters()
  }

  reloadWithFilters() {
    const filter = this.buildFilter()
    //console.log('[ ChatList#reloadWithFilters ] filter: ', filter)
    if (this.chats) {
      this.loading = true
      if (this.chats.pagination) {
        this.chats.pagination = {
          ...this.chats.pagination,
          current_page: 1
        }
      }
      this.chats.setFilterWithCleanup(filter).finally(() => this.loading = false)
    }
  }

  private buildFilter(): Dictionary<any> {
    let filter: Dictionary<any> = {}
    if (this.channelId) {
      filter['channel'] = this.channelId
    }
    if (this.searchText) {
      filter['search'] = this.searchText
    }
    if (this.onlyAttention) {
      filter['attention'] = true
    }
    return filter
  }

  private buildResourceCollection(channelId: string | null, searchText: string | null, filter: Dictionary<any> = {}): ResourceCollection<Chat> {
    const namePrefix = channelId ? `infinity-by-channel-${channelId}` : `infinity-all-chats`;
    const collectionName = searchText ? `${namePrefix}-${searchText}` : namePrefix;
    //console.log('[ ChatList#buildResourceCollection ]: ', { channelId, searchText }, 'cn: ' + collectionName)
    return new ResourceCollection<Chat> (
        Chat.api()
            .page(1)
            .perPage(15)
            .filter(filter)
            .with(['channel', 'channelUser', 'latestChatMessage', 'bookmark', 'conversation'])
            .orderBy('modifiedDate', "desc"),
        collectionName,
        true
    )
  }

  private getChatStateClass(chat: Chat): string {
    if (chat && chat.channelUser && chat.channelUser.state) {
      if (chat.channelUser.state === 'ACTIVE') {
        return `chat-user-state-${chat.channelUser.state.toLowerCase()}-${chat.lastActor?.toLowerCase()}`
      }
      return `chat-user-state-${chat.channelUser.state.toLowerCase()}`
    }
    return `chat-user-state-unknown`
  }

  get sortedItems(): Chat[] {
    if (this.chats) {
      return this.chats.items.sort(byPropertiesOf<Chat>(['-modifiedDate']))
    }
    return []
  }
  subscribe() {
    this.$stompClient.$subscribe({
      topicName: `/chat/activity`,
      subscriptionName: `chat-activity`,
      callback: (data) => this.onEventReceive(data)
    })
    //console.log('[ ChatList#subscribe ] subscribed to:', `/chat/activity`)
  }

  onEventReceive(data: any) {
    //console.log('[ ChatList#onEventReceive ] socket event:', data)
    // this.loadData()
    if (data && data.chatId && data.actor && this.chats/* && data.actor === 'USER'*/) {
      Chat.api()
          .with(['channel', 'channelUser', 'latestChatMessage', 'bookmark', 'conversation'])
          .find(data.chatId).then(chat => {
            //console.log('[ ChatList#onEventReceive ] load new? ', chat);
            //console.log('[ ChatList#onEventReceive ] toast? ', (chat.attention && !!chat.latestChatMessage));

            if (chat.attention && chat.latestChatMessage) {
              const chatLocation: RawLocation = { params: {chatId: chat.id} }
              if (this.$route.name) {
                if (['allChats', 'allChatsChat'].includes(this.$route.name)) {
                  chatLocation.name = 'allChatsChat'
                }
                else if (['chatsByChannel', 'chatsByChannelChat'].includes(this.$route.name)) {
                  chatLocation.name = 'chatsByChannelChat'
                }
              }

              const sound = new Audio('/sound/bell.mp3');
              sound.play().then(() => {
                // no action
              }).catch((err) => {
                //console.warn('play sound error: ', err)
              })

              this.$bvToast.toast(chat.latestChatMessage.text as string, {
                title: 'Новое сообщение',
                variant: 'warning',
                noCloseButton: false,
                to: chatLocation,
                autoHideDelay: 7500
              })


            }

            if (this.chats) {
              //console.log('[ ChatList#onEventReceive ] run syncWithStore()...');
              this.chats.syncWithStore();
              chat.channelUser?.load('user')
              chat.channelUser?.load('profile')
            }
            // this.chats.initFromStore()
          })
    }
  }

  isSelected(chat: Chat): boolean {
    return (this.selectedChat && this.selectedChat.id === chat.id) || (this.externalSelectedChat && this.externalSelectedChat.id === chat.id)
  }
  @Emit()
  onChatSelect(chat: Chat): Chat {
    //console.log('[ ChatList#onChatSelect ] emit: ', chat)
    this.selectedChat = chat
    return this.selectedChat
  }

  handleScroll(event: any) {
    const { scrollTop, offsetHeight, scrollHeight } = event.currentTarget
    // if (!this.loading) {
    //   this.scrollHeight = scrollHeight
    // }
    const currentPos = scrollTop + offsetHeight;
    if (this.chats && currentPos > scrollHeight && !this.loading && !this.chats.$isLastPage) {
      this.loading = true
      this.chats.nextPage().finally(() => {
        this.loading = false
        // this.scrollHeight = scrollHeight
      })
    }
  }
}
