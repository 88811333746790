import { Attr, Meta, HasMany, BelongsTo } from "@temed/vue-jsonapi-orm";
import { ApiResourceWithDates } from "@/models/base/ApiResourceWithDates";
import { ChannelUser, Chat, ChatPlan, Project } from "@/models/index";
import {ChannelType} from "@/models/types/Types";
import {Assistant} from "@/models/Assistant";

export class Channel extends ApiResourceWithDates {
    static jsonApiType = "channels";

    @Attr() channelType?: ChannelType;
    @Attr() userName?: string;
    @Attr() token?: string;
    @Attr() secret?: string;
    @Attr() description?: string;
    @Attr() enabled?: boolean;
    @Meta() registered?: boolean;
    @Attr() autoStart?: boolean;
    @Attr() maxConnections?: number;
    @Attr() dropPendingUpdates?: boolean;
    @Attr() webhookUrl?: string;
    @BelongsTo() defaultPlan?: ChatPlan | null;
    @BelongsTo() project?: Project;
    @HasMany() channelUsers?: ChannelUser[];
    @HasMany() chats?: Chat[];

    @Attr() welcomeText?: string
    @Attr() privateWelcomeText?: string
    @Attr() reminderTimeout?: number
    @Attr() reminderText?: string

    @Attr(false) broadcastEnabled?: boolean

    @Attr(false) closed?: boolean
    @Attr() staticAllowUsers?: number[] | null
    @Attr() staticManagers?: number[]
    @Attr() staticAdmins?: number[]

    @Attr(false) allowAssistants?: boolean
    @BelongsTo() defaultAssistant?: Assistant | null


    /* helpers */
    get $isTelegram() {
        return this.channelType === ChannelType.TELEGRAM
    }
    get $isWhatsapp() {
        return this.channelType === ChannelType.WHATSAPP
    }
    get $isWechat() {
        return this.channelType === ChannelType.WECHAT
    }

    get hasDefaultPlan(): boolean {
        return this.defaultPlan != null
    }

    get hasDefaultAssistant(): boolean {
        return this.defaultAssistant != null
    }

    get staticAllowUsersAsString() {
        if (this.staticAllowUsers && this.staticAllowUsers.length) {
            return this.staticAllowUsers.join(", ")
        }
        return ""
    }

    set staticAllowUsersAsString(value: string) {
        const strItems = value.split(",")
        if (strItems && strItems.length) {
            const allowedIds: number[] = []
            strItems.forEach(strId => {
                const numericId = parseInt(strId.trim())
                if (!isNaN(numericId)) {
                    allowedIds.push(numericId)
                }
            })
            this.staticAllowUsers = allowedIds.length ? allowedIds : null
        }
        else {
            this.staticAllowUsers = null
        }
    }

    get telegramInviteBase64Link() {
        if (!this.$isTelegram || !this.userName  || !this.defaultPlan || !this.defaultPlan.keyName) {
            return undefined
        }
        const base64hash = btoa("admin:" + this.defaultPlan.keyName).replaceAll("=", '')
        return `https://t.me/${this.userName}?start=${base64hash}`
    }
    static buildTelegramInviteBase64Link(channel: Channel, utmSource: string): string | undefined {
        if (!utmSource || !utmSource.length) {
            utmSource = "admin"
        }

        if (!channel.$isTelegram || !channel.userName  || !channel.defaultPlan || !channel.defaultPlan.keyName) {
            return undefined
        }
        const base64hash = btoa(utmSource + ":" + channel.defaultPlan.keyName).replaceAll("=", '')
        return `https://t.me/${channel.userName}?start=${base64hash}`
    }
    get telegramInviteOpenLink() {
        if (!this.$isTelegram || !this.userName || !this.defaultPlan || !this.defaultPlan.keyName) {
            return undefined
        }
        return `https://t.me/${this.userName}?start=admin_${this.defaultPlan.keyName}`
    }
}