import { Attr, HasMany, BelongsTo, Meta } from "@temed/vue-jsonapi-orm";
import { ApiResourceWithDates } from "@/models/base/ApiResourceWithDates";
import {ChatPlan} from "@/models/ChatPlan";
import {ChannelUser} from "@/models/ChannelUser";
import MappedAnswer, {IntegerValue, StringValue, TimeValue} from "@/models/types/Types";

export class UserAnswer extends ApiResourceWithDates {
    static jsonApiType = "userAnswers";

    @BelongsTo() chatPlan?: ChatPlan;
    @BelongsTo() channelUser?: ChannelUser;
    @Meta() valueName?: string;
    @Meta() stepId?: string;
    @Meta() step?: string;
    @Meta() stateCode?: string;
    @Meta() answer?: IntegerValue | StringValue | TimeValue;
    @Meta() additional?: MappedAnswer[];
    @Meta() comment?: string;
    @Meta() numberValue?: number;
    @Meta() answerType?: string;
}