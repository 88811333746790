
import {Vue, Component} from 'vue-property-decorator';
import {ResourceCollection} from "@temed/vue-jsonapi-orm";
import {Assistant} from "@/models";
import dayjs from "dayjs";

@Component({})
export default class AssistantsTable extends Vue {
  private assistants = new ResourceCollection<Assistant>(
      Assistant.api().page(1).perPage(25)
  )

  mounted() {
    this.assistants.requestItems()
  }

  onRowClick(item: any, index: any, event: any) {
    console.log('onRowClick:', { item, index, event })
    this.$router.push({ name: 'assistantCard', params: { assistantId: item.id }})
  }

  get tableItems() {
    if (!this.assistants.items || !this.assistants.items.length) return []
    return this.assistants.items.map(item => {
      return {
        id: item.id,
        ...item.attributes,
        ...item.$original?.meta
      }
    })
  }

  private tableColumns = [
    {
      key: 'name',
      sortable: true
    },
    {
      key: 'model',
      sortable: true
    },
    {
      key: 'keyName',
      sortable: true
    },
    {
      key: 'description',
      sortable: false
    },
    {
      key: 'createdDate',
      formatter: this.dayjsFormatter,
      sortable: true,
      label: 'Создано'
    },
    {
      key: 'modifiedDate',
      formatter: this.dayjsFormatter,
      sortable: true,
      label: 'Изменено'
    }
  ]

  dayjsFormatter(value: any, key: string, item: {[key: string]: any}) {
    return value ? dayjs(value).format("DD.MM.YYYY, HH:mm:ss") : null
  }
}
