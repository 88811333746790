
import {Vue, Component, Watch} from 'vue-property-decorator';
import AssistantsTable from "@/components/assistant/AssistantsTable.vue";

@Component({
  components: {AssistantsTable}
})
export default class AssistantView extends Vue {
  private childName: string | null = null

  mounted() {
    console.log('{ AssistantView::mounted }')
  }

  get isViewRoute() {
    return this.$route.name === 'assistants'
  }
  @Watch("$route.name")
  onRouteChange() {
    if (this.isViewRoute) {
      this.childName = null
    }
  }
  onChildOpen(childName: string ) {
    this.childName = childName;
  }

  toCreateNewAssistant() {
    this.$router.push({ name: 'assistantCard', params: { assistantId: 'create' } })
  }
}
