import {AbstractTaskDescriptor, WebTaskType} from "@/models/types/Types";

export class TestTask implements AbstractTaskDescriptor {
    type: WebTaskType = WebTaskType.TEST;
    channelId: string | null = null;
    channelName: string | null = null;
    length = 50;
    delay = 350;

    constructor(task: AbstractTaskDescriptor | undefined = undefined) {
        if (task) {
            //console.log("apply raw task: ", task, "to this: ", this)
            this.channelId = task.channelId
            this.channelName = task.channelName
        }
    }
}