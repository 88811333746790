import {Attr, BelongsTo} from "@temed/vue-jsonapi-orm";
import { ApiResourceWithDates } from "@/models/base/ApiResourceWithDates";
import {BookEmployee} from "@/models/BookEmployee";
import {Chat} from "@/models/Chat";
export class BookClinic extends ApiResourceWithDates {
    static jsonApiType = "bookClinics";

    @Attr() name?: string;
    @Attr() shortName?: string;
    @Attr() address?: string;
    @Attr() phone?: string;
    @Attr() email?: string;
    @Attr() description?: string;
    @Attr() latitude?: number;
    @Attr() longitude?: number;
    @Attr() enabled?: boolean;
    @Attr() city?: string;
    @Attr() topicId?: number;

    @BelongsTo() head?: BookEmployee
    @BelongsTo() chat?: Chat
}