import { Attr } from "@temed/vue-jsonapi-orm";
import { ApiResourceWithDates } from "@/models/base/ApiResourceWithDates";
import {Scenario} from "@/models/types/ScenarioTypes";
export class ChatPlan extends ApiResourceWithDates {
    static jsonApiType = "chatPlans";

    @Attr() name?: string;
    @Attr() description?: string;
    @Attr() active?: boolean;
    @Attr() code?: string;
    @Attr() keyName?: string;
    @Attr() scenario?: Scenario;

}