import {Attr, BelongsTo, HasMany, Meta} from "@temed/vue-jsonapi-orm";
import { ApiResourceWithDates } from "@/models/base/ApiResourceWithDates";
import {Attachment} from "@/models/Attachment";


export class User extends ApiResourceWithDates {
    static jsonApiType = 'users';

    @Meta() telegramId?: number;
    @Meta() source?: string;
    @Meta() imported?: boolean;
    @Meta() firstName?: string
    @Meta() lastName?: string
    @Meta() userName?: string
    @Meta() isPremium?: boolean;
    @Meta() isBot?: boolean;
    @Meta() languageCode?: string
    @Meta() canJoinGroups?: boolean;
    @Meta() canReadAllGroupMessages?: boolean;
    @Meta() supportInlineQueries?: boolean;
    @Meta() addedToAttachmentMenu?: boolean;

    @BelongsTo() profilePhoto?: Attachment;
    @HasMany() photos?: Attachment[];


    get preferName(): string | undefined {
        const nameParts: string[] = []
        if (this.firstName) nameParts.push(this.firstName)
        if (this.lastName) nameParts.push(this.lastName)

        if (nameParts.length) {
            return nameParts.join(' ')
        }
        else if (this.userName) {
            return '@' + this.userName
        }
        return undefined
    }

    /**
     * Request active User
     */
    static async requestActiveUser(): Promise<User> {
        const builder = User.api()
            // .with(['roles', 'profileImage'])
        builder.path = User.apiPath
        const response = await builder.request('users')
        //console.log("[ User::requestActiveUser ] builder:", builder)
        //console.log("[ User::requestActiveUser ] response:", response)
        return User.resourceFromResponse(response.data).data
    }
}
