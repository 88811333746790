import {AbstractTaskDescriptor, WebTaskType} from "@/models/types/Types";

export class ImportTask implements AbstractTaskDescriptor {
    type: WebTaskType = WebTaskType.USER_IMPORT;
    channelId: string | null = null;
    channelName: string | null = null;
    startFrom: number | null = null;
    limit: number | null = null;
    size: number | null = null;
    sheetId: string | null = null;
    pageName: string | null = null;
    source: string | null = null;
    chatPlanName: string | null = null;
    chatPlanId: string | null = null;


    constructor(task: AbstractTaskDescriptor | undefined = undefined) {
        if (task) {
            //console.log("apply raw task: ", task, "to this: ", this)
            this.channelId = task.channelId
            this.channelName = task.channelName
        }
    }
}