
import {Component, Vue} from 'vue-property-decorator';
import axiosInstance from "@/axios";

import {SimpleUserChannelDto} from "@/models/types/Types";
import AnswerChart from "@/components/report/AnswerChart.vue";
import AnswerHistory from "@/components/report/AnswerHistory.vue";

@Component({
  components: {AnswerHistory, AnswerChart}
})
export default class AnswerReportView extends Vue {

  private channels: SimpleUserChannelDto[] = [];

  private isLoading = false
  private isLoadDone = false

  mounted() {
    // if (this.isCompositeKey) {
    //   this.loadChannelVariance()
    // }
    this.loadChannelVariance()
  }

  loadChannelVariance() {
    const { channelUserId } = this.$route.params;
    const key = this.keyFromCompositeKey
    this.isLoadDone = false
    this.isLoading = true
    axiosInstance.get(`/api/reports/external/${key}:${channelUserId}/channels`)
        .then(response => {
          this.channels = response.data.map((dto: SimpleUserChannelDto) => {
            return dto
          });
          this.isLoadDone = true
        })
        .catch(error => {
          //
        })
        .finally(() => {
          this.isLoading = false
        })
  }

  get isCompositeKey(): boolean {
    const { key } = this.$route.params;
    return key ? key?.includes(".") : false
  }



  get keyFromCompositeKey(): string {
    const { key } = this.$route.params;
    if (key.includes(".")) {
      const keyPartial = key.split(".")
      return keyPartial[keyPartial.length - 1]
    }
    return key
  }
}
