import { Attr, Meta, HasMany, BelongsTo } from "@temed/vue-jsonapi-orm";
import {ApiResourceWithDates} from "@/models/base/ApiResourceWithDates";
import {DayjsMeta} from "@/decorators/decorators";
import {Dayjs} from "dayjs";
import {ImportTaskData, TestTaskData, WebTaskState, WebTaskType} from "@/models/types/Types";
import {TestTask} from "@/models/embedded/task/TestTask";
import {Project} from "@/models/Project";
import {ImportTask} from "@/models/embedded/task/ImportTask";
import {UserCheckTask} from "@/models/embedded/task/UserCheckTask";
import {UserProfileSyncTask} from "@/models/embedded/task/UserProfileSyncTask";
import {Dictionary} from "vue-router/types/router";
import {Vue} from "vue-property-decorator";

export class WebTask extends ApiResourceWithDates {
    static jsonApiType = 'webTasks';

    @BelongsTo() project?: Project
    @Attr() taskType?: WebTaskType
    @Attr() state?: WebTaskState
    @Meta() progress?: number
    @Attr() description?: string
    @Attr() task!: TestTask | ImportTask | UserCheckTask | UserProfileSyncTask | null
    @Meta() data!: TestTaskData | ImportTaskData | null
    @Meta() lastMessage?: string
    @DayjsMeta() startTime?: Dayjs
    @Meta() duration?: string


    // constructor() {
    //     super({}, true);
    //     //console.log('{ WebTask#constructor }', this)
    // }
    // constructor(attributes: Dictionary<any> = {}, withDefaults = true) {
    //     super(attributes, withDefaults);
    //     //console.log('{ WebTask#constructor } ', { attributes: attributes, withDefaults: withDefaults, $this: this })
    //     if (this.task === null || !this.task) {
    //         //console.warn('{ WebTask#constructor } set task to null')
    //         this.task = null
    //     }
    //     else {
    //         //console.warn('{ WebTask#constructor } swe have a task =>', this.task)
    //     }
    // }

    static createInstance(taskType: WebTaskType, project: Project): WebTask {
        const newTask = new WebTask()
        newTask.project = project
        newTask.taskType = taskType

        switch (taskType) {
            case WebTaskType.TEST:
                newTask.task = new TestTask()
                //Vue.set(newTask, 'task', new TestTask())
                break;
            case WebTaskType.USER_IMPORT:
                newTask.task = new ImportTask()
                //Vue.set(newTask, 'task', new ImportTask())
                break;
            case WebTaskType.USER_CHECK:
                newTask.task = new UserCheckTask()
                //Vue.set(newTask, 'task', new UserCheckTask())
                break;
            case WebTaskType.USER_PROFILE_SYNC:
                newTask.task = new UserProfileSyncTask()
                //Vue.set(newTask, 'task', new UserProfileSyncTask())
                break;
            default:
                newTask.task = null
                //Vue.set(newTask, 'task', null)
                break;
        }

        return newTask;
    }
}