import {Attr, BelongsTo} from "@temed/vue-jsonapi-orm";
import { ApiResourceWithDates } from "@/models/base/ApiResourceWithDates";
import {Sex} from "@/models/types/Types";
import {BookClinic} from "@/models/BookClinic";


export class BookEmployee extends ApiResourceWithDates {
    static jsonApiType = "bookEmployees";

    @Attr() fio?: string;
    @Attr() firstName?: string;
    @Attr() lastName?: boolean;
    @Attr() middleName?: string;
    @Attr() spec?: string;
    @Attr() apecAliases?: string[];
    @Attr() description?: string;
    @Attr() enabled?: boolean;
    @Attr() fired?: boolean;
    @Attr() comment?: string;
    @Attr() sex?: Sex;

    @BelongsTo() clinic?: BookClinic
}