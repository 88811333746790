
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {Channel, Project} from "@/models";
import ChannelTypeIcon from "@/components/channel/ChannelTypeIcon.vue";
import ChannelCard from "@/components/channel/ChannelCard.vue";
import {ResourceCollection} from "@temed/vue-jsonapi-orm";
import ChannelUserCounterBadge from "@/components/channel/ChannelUserCounterBadge.vue";

@Component({
  components: {ChannelUserCounterBadge, ChannelCard, ChannelTypeIcon}
})
export default class ChannelsTab extends Vue {
  @Prop() project!: Project;

  private editMode = false
  private selectedChannel: Channel | null = null;

  private channels = new ResourceCollection<Channel> (
      Channel.api().with(['defaultPlan', 'defaultAssistant']).perPage(25).page(1)
  )


  mounted() {
    //console.log('[ ChannelsTab::mounted ]')
    this.loadData()
  }
  created() {
    //console.log('[ ChannelsTab::created ]')
  }

  @Watch("project")
  loadData() {
    if (!this.project) {
      return;
    }

    //console.log("[ ChannelsTab::loadData ] load channels by project: " + this.project.id)
    this.channels.setFilter({project: this.project.id}).then(channels => {
      if (channels.length) {
        this.selectedChannel = channels[0]
      }
    });
  }

  selectChannel(channel: Channel) {
    //console.log("selectChannel :", channel)
    this.selectedChannel = channel;
  }
  subButtonClick(channel: Channel) {
    //console.log("subButtonClick :", channel)
  }

  setEditState(state: boolean) {
    this.editMode = state
  }

  createNew() {
    const newChannel = new Channel()
    newChannel.project = this.project
    this.selectChannel(newChannel);
    this.editMode = true;
  }

  onCreateCancel(newChannel: Channel) {
    //console.log("[ ChannelsTab::onCreateCancel ] destroy channel: " + newChannel)
    this.editMode = false;
    if (this.channels.items.length) {
      this.selectedChannel = this.channels.items[0]
    }
    else {
      this.selectedChannel = null
    }
  }
}
