
import {Component, Emit, Mixins, Prop, Vue} from "vue-property-decorator";
import {Project, WebTask} from "@/models";
import {ResourceCollection} from "@temed/vue-jsonapi-orm";
import FormattersMixin from "@/mixins/FormattersMixin";
import {
  AbstractTaskData,
  EventLevel,
  ImportTaskData,
  TestTaskData,
  WebTaskSocketEvent,
  WebTaskState
} from "@/models/types/Types";
import dayjs from "dayjs";

@Component({
  computed: {
    EventLevel() {
      return EventLevel
    },
    WebTaskState() {
      return WebTaskState
    }
  }
})
export default class WebTaskTable extends Mixins(Vue, FormattersMixin) {
  @Prop() private project!: Project

  private subscribesProgress: { [key: string]: WebTaskSocketEvent } = {};
  private showDetails: {[key: string]: boolean} = {}

  private webTasks = new ResourceCollection(
      WebTask.api().page(1).perPage(25),
      'wt_table'
  )

  /**
   * Table columns
   * */
  private fields = [
    {
      key: 'channelName',
      label: 'Канал, описание задачи'
    },
    {
      key: 'taskType',
      label: 'Тип задачи'
    },
    {
      key: 'state',
      label: 'Статус',
      thStyle: { 'min-width': '140px' }
    },
    {
      key: 'progress',
      label: 'Прогресс'
    },
    {
      key: 'duration',
      label: 'Время'
    },
    {
      key: 'task',
    },
    {
      key: 'createdDate',
      formatter: this.dayjsFormatter,
      sortable: true,
      label: 'Создано'
    },
    {
      key: 'startTime',
      formatter: this.dayjsFormatter,
      sortable: true,
      label: 'Запущено'
    },
    {
      key: 'modifiedDate',
      formatter: this.dayjsFormatter,
      sortable: true,
      label: 'Изменено'
    },
    {
      key: 'action',
      label: 'Действия',
      stickyColumn: true
    }
  ]

  mounted() {
    //console.log("[ WebTaskTable#mounted ]")
    this.loadData()
  }

  loadData() {
    if (this.project) {
      this.webTasks.setFilter({ project: this.project.id }).then(items => {
        items.forEach(item => {
          const eventData = { progress: item.progress, duration: item.duration, data: {}, messages: [] };
          this.$set(this.subscribesProgress, item.id, eventData)
          this.$stompClient.$subscribe({
            topicName: `/webTask/${item.id}`,
            subscriptionName: `webTask-${item.id}`,
            callback: (data) => this.onTaskProgress(item.id, data)
          })
        })
      })
    }
  }

  toggleDetails(id: string) {
    if (!this.showDetails[id]) {
      this.$set(this.showDetails, id, true)
    }
    else {
      this.$set(this.showDetails, id, false)
    }
  }

  /**
   * Обновление статуса прогресса задачи в локальном кеше подписок
   * (callback на входящее сообщение по подписке)
   * */
  onTaskProgress(id: string, data: AbstractTaskData) {
    const eventTime = dayjs();
    /* если в кеше нет секции с событиями по таску - делаем первичную инициализацию */
    if (!this.subscribesProgress[id]) {
      const eventData = { progress: data.progress, duration: data.duration, data: data, messages: [ { ...data.message, time: eventTime } ] }
      //console.log('create new record: ', eventData)
      this.$set(this.subscribesProgress, id, eventData)
    }
    /* обновляем кеш событий по таску, устанавливаем метку времени */
    else {
      const eventData = this.subscribesProgress[id]
      //console.log('update existent record: ', eventData)
      eventData.progress = data.progress
      eventData.duration = data.duration
      eventData.data = data
      if (data.message != null) {
        eventData.messages.push({ ...data.message, time: eventTime })
        // eventData.messages.splice(0, 0, { ...data.message, time: eventTime })
      }
      this.$set(this.subscribesProgress, id, eventData)
    }

    if (["complete", "error", "break"].includes(data.status)) {
      //console.log('{ #onTaskProgress } load one record ', id)
      WebTask.api().find(id)
    }
  }


  /**
   * Table items convert
   * */
  get items() {
    if (!this.webTasks.items) return []
    return this.webTasks.items.map(item => {
      return {
        id: item.id,
        $isSaving: item.$isSaving,
        attrs: item.changedAttributes,
        channelName: (item.task && item.task.channelName) ? '@' + item.task.channelName : null,
        ...item.attributes,
        ...item.$original?.meta,
        _showDetails: this.showDetails[item.id]
      }
    })
  }

  /**
   * Установка статуса задачи
   * */
  setWebTaskState(id: string, newState: WebTaskState) {
    //console.log('[ WebTaskTable#setWebTaskState ]', id, 'to state', newState)
    if (this.subscribesProgress[id] && newState === WebTaskState.IN_PROGRESS) {
      //console.log('[ WebTaskTable#setWebTaskState ] reset messages log...')
      const eventData = this.subscribesProgress[id]
      eventData.messages = []
      this.$set(this.subscribesProgress, id, eventData)
    }
    const task = WebTask.fromId(id)
    task.state = newState
    task.save()
  }

  taskData(id: string): AbstractTaskData | ImportTaskData | TestTaskData | null {
    const webTask = WebTask.fromId(id);
    if (webTask.state === WebTaskState.IN_PROGRESS && this.subscribesProgress[id]?.data) {
      return this.subscribesProgress[id].data
    }
    return webTask.data
  }

  /**
   * Перебрасываем в вышестоящий компонент идентификатор задачи, выбранной для редактирования
   * */
  @Emit()
  taskToEdit(id: string) {
    //console.info('[ WebTaskTable#taskToEdit ] id: ', id)
    return id
  }

}
