
import {Component, Emit, Prop, Vue} from "vue-property-decorator";
import {WebTask} from "@/models/WebTask";
import {WebTaskType} from "@/models/types/Types";
import TestTaskDescriptor from "@/components/webtask/partial/TestTaskDescriptor.vue";
import ImportTaskDescriptor from "@/components/webtask/partial/ImportTaskDescriptor.vue";
import {ImportTask} from "@/models/embedded/task/ImportTask";
import {ResourceCollection} from "@temed/vue-jsonapi-orm";
import {Channel, Project} from "@/models";

@Component({
  computed: {
    ImportTask() {
      return ImportTask
    },
    WebTaskType() {
      return WebTaskType
    }
  },
  components: {TestTaskDescriptor, ImportTaskDescriptor}
})
export default class WebTaskEditForm extends Vue {
  @Prop() private task!: WebTask | null
  @Prop() private taskType!: WebTaskType
  @Prop() private project!: Project;

  private selectedChannelId: string | null = null

  private channels = new ResourceCollection<Channel>(
      Channel.api().filter({ project: this.project.id }).page(1).perPage(100)
  )



  private webTaskTypeOptions = [
    { value: WebTaskType.TEST, text: "Тестовое задание" },
    { value: WebTaskType.USER_IMPORT, text: "Импорт пользователей" },
    { value: WebTaskType.USER_CHECK, text: "Проверка статуса блокировки", disabled: true },
    { value: WebTaskType.USER_PROFILE_SYNC, text: "Синхронизация профилей", disabled: true },
  ]

  mounted() {
    //console.log('[ WebTaskEditForm#mounted ]')
    /* создаем новый экземпляр задачи, если на вход компонента не пришел готовый объект для редактирования */
    if (!this.task && this.taskType && this.project) {
      this.task = WebTask.createInstance(this.taskType, this.project)
    }
    this.channels.requestItems()
  }


  onChannelSelect(selectedId: string) {
    //console.log("selectedChannelId: " + selectedId)
    this.selectedChannelId = selectedId;

    if (selectedId == null && this.task && this.task.task) {
      this.task.task.channelId = null;
      this.task.task.channelName = null;
      return
    }

    // const selectedChannel = this.channels.items.find(channel => channel.id === selectedId)
    const selectedChannel = Channel.fromId(this.selectedChannelId)
    //console.log("selected channel from items: ", selectedChannel)
    if (selectedChannel && this.task && this.task.task) {
      const { task } = this.task;
      if (task) {
        task.channelId = selectedChannel.id
        task.channelName = selectedChannel.userName ? selectedChannel.userName : null
      }
      this.$set(this.task, 'task', task)
      // this.task.task.channelId = selectedChannel.id;
      // this.task.task.channelName = selectedChannel.userName ? selectedChannel.userName : null;
    }


    // this.$nextTick(() => {
    //   this.$nextTick(() => {
    //     //console.log("{ $nextTick } selected channel from items: ", selectedChannel)
    //     if (selectedChannel && this.task && this.task.task) {
    //       this.task.task.channelId = selectedChannel.id;
    //       this.task.task.channelName = selectedChannel.userName ? selectedChannel.userName : null;
    //     }
    //   })
    // })
  }

  @Emit()
  onCreate() {
    return this.task?.save()
        .then((task) => {
          //console.log('created task: ', task)
          return task
        })
        .catch(err => {
          //console.warn('created task error: ', err)

        })
  }

  @Emit('on-create')
  cancel() {
    return null
  }

}
