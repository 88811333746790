import {Attr, BelongsTo, Meta} from "@temed/vue-jsonapi-orm";
import { ApiResourceWithDates } from "@/models/base/ApiResourceWithDates";
import {BookEmployee} from "@/models/BookEmployee";
import {BookClientHistoryRecord, BookClientStandardRecord, BookClientStatusRecord, Sex} from "@/models/types/Types";
import {BookClinic} from "@/models/BookClinic";
import {byPropertiesOf} from "@/types/sorter";
import {Chat} from "@/models/Chat";
import dayjs from "dayjs";
export class BookClient extends ApiResourceWithDates {
    static jsonApiType = "bookClients";
    static jsonApiTypePath = "book/clients";

    @Attr() name?: string;
    @Attr() code?: string;
    @Attr() firstName?: string;
    @Attr() lastName?: string;
    @Attr() middleName?: string;
    @Attr() set?: Sex;
    @Attr() phone?: string;
    @Attr() email?: string;
    @Attr() status?: string;
    @Meta() statusHistory?: BookClientStatusRecord[];
    @Meta() standardHistory?: BookClientStandardRecord[];
    @Attr() inviteDate?: string;
    @Attr() standards?: string[];

    @BelongsTo() clinic?: BookClinic
    @BelongsTo() employee?: BookEmployee

    get clientHistory(): BookClientHistoryRecord[] {
        let history: BookClientHistoryRecord[] = []
        if (this.statusHistory && this.statusHistory.length) {
            history = this.statusHistory.map(it =>  {
                return { date: dayjs(it.date).utc(false), status: it.status }
            })
        }
        if (this.standardHistory && this.standardHistory.length) {
            history = [
                ...history,
                ...this.standardHistory.map(it =>  {
                    return { date: dayjs(it.date).utc(false), name: it.name }
                })
            ]
        }
        return history.sort(byPropertiesOf<BookClientHistoryRecord>(['date']))
    }
}