import dayjs from "dayjs";
import {ApiResourceBase} from "@temed/vue-jsonapi-orm";
import {underscore} from "inflected";

export function DayjsMeta() {
    return (target: any, propertyKey: string) => {
        /**
         * Get original meta data.
         */
        const getter = function (this: ApiResourceBase) {
            return dayjs(this.$getMeta(propertyKey))
        }
        Object.defineProperty(target, propertyKey, {
            get: getter,
        })
    }
}

/**
 * Define getter and setter for resource belongsTo or hasOne relation.
 *
 * @constructor
 */
export const NullableBelongsTo = () => {
    return (target: ApiResourceBase, relationKey: string) => {
        (target.constructor as typeof ApiResourceBase)._relationshipKeys.push(
            relationKey
        )
        const staticProps = target.constructor as typeof ApiResourceBase
        let relationshipsKey = relationKey
        if (staticProps.convertSnakeCase) {
            relationshipsKey = underscore(relationKey)
        }
        /**
         * Get value from changed attribute list
         * fall back to original property.
         */
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const getter = function (this: ApiResourceBase) {
                const relation = this.relations[relationshipsKey]
                if (!relation) {
                    return undefined
                } else if (!Array.isArray(relation)) {
                    // check soft deleted relations
                    return !relation.$markedForDeletion ? relation : null
                } else {
                    throw new Error(
                        `Relationship ${relationKey} is not annotated correctly`
                    )
                }
            }

        /**
         * Set value and track changes.
         * 1.0.11 - add support for null value (for drop relation on client-side)
         * @param newValue
         */
        const setter = function (this: ApiResourceBase, newValue: ApiResourceBase) {
            this._makeEditable()
            if (newValue) {
                newValue.addObserver(this)
            }
            this.relations[relationshipsKey] = newValue
            const identifier = newValue ? newValue.$toIdentifier() : null
            const oldIdentifier = this.relationships[relationshipsKey]?.data
            if (relationshipsKey in this.relationships) {
                this.relationships[relationshipsKey].data = identifier
            } else {
                this.relationships[relationshipsKey] = {
                    data: identifier,
                }
            }
            if (this.$trackChanges) {
                if (
                    oldIdentifier &&
                    !Array.isArray(oldIdentifier) &&
                    identifier &&
                    oldIdentifier.type === identifier.type &&
                    oldIdentifier.id === identifier.id
                ) {
                    delete this.changedRelationships[relationshipsKey]
                } else {
                    this.changedRelationships[relationshipsKey] = {
                        data: identifier,
                    }
                }
            }
        }

        // ..@ts-ignore
        // const defaultValue: any = target[relationKey]
        // if (defaultValue) {
        //   setter(defaultValue)
        // }

        Object.defineProperty(target, relationKey, {
            get: getter,
            set: setter,
            configurable: true,
            enumerable: true,
        })
    }
}