import { render, staticRenderFns } from "./WebTaskTable.vue?vue&type=template&id=bf822f14&scoped=true&"
import script from "./WebTaskTable.vue?vue&type=script&lang=ts&"
export * from "./WebTaskTable.vue?vue&type=script&lang=ts&"
import style0 from "./WebTaskTable.vue?vue&type=style&index=0&id=bf822f14&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bf822f14",
  null
  
)

export default component.exports