import {AbstractTaskDescriptor, WebTaskType} from "@/models/types/Types";

export class UserProfileSyncTask implements AbstractTaskDescriptor {
    type: WebTaskType = WebTaskType.USER_PROFILE_SYNC;
    channelId: string | null = null;
    channelName: string | null = null;

    constructor(task: AbstractTaskDescriptor | undefined = undefined) {
        if (task) {
            //console.log("apply raw task: ", task, "to this: ", this)
            this.channelId = task.channelId
            this.channelName = task.channelName
        }
    }
}