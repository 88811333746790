import axios, { AxiosRequestConfig } from "axios";
import Vue from "vue";

const axiosInstance = axios.create({
    // baseURL: 'http://localhost:8081',
    headers: {
        "Content-Type": "application/vnd.api+json"
    }
});

axiosInstance.interceptors.request.use((config: AxiosRequestConfig) => {
    if (Vue.prototype.$keycloak.authenticated && config.headers) {
        config.headers.Authorization = `Bearer ${Vue.prototype.$keycloak.token}`
    }
    config.withCredentials = true;
    return config;
})

axiosInstance.interceptors.response.use(
    function(response) {
        return Promise.resolve(response);
        },
    function(error) {
        console.log('response axios error:', error)
        // handle error
        //const message = error.response.data.error.message || error.response.statusText
        const vm = new Vue()
        vm.$bvToast.toast(`${error.response.statusText} (${error.response.status})`, {
            title: 'API Error',
            variant: 'danger',
            noCloseButton: false
        })
        return Promise.reject(error)
    });

export default axiosInstance