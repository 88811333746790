import {Component, Prop, Vue} from "vue-property-decorator";
import {ResourceCollection} from "@temed/vue-jsonapi-orm";
import {Channel} from "@/models";
import {WebTask} from "@/models/WebTask";

export default class DefaultDescriptor extends Vue {
  @Prop() task!: WebTask
  @Prop() channels!: ResourceCollection<Channel>
  selectedChannelId: string | null = null

  mounted() {
    //console.log("{ DefaultDescriptor } mounted")
  }

  onChannelSelect(selectedId: string) {
    //console.log("selectedChannelId: " + selectedId)
    this.selectedChannelId = selectedId;

    if (selectedId == null && this.task && this.task.task) {
      this.task.task.channelId = null;
      this.task.task.channelName = null;
      return
    }

    const selectedChannel = this.channels.items.find(it => it.id === selectedId)
    if (selectedChannel && this.task && this.task.task) {
      this.task.task.channelId = selectedChannel.id;
      this.task.task.channelName = selectedChannel.userName ? selectedChannel.userName : null;
    }
  }
}