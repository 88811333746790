import { Attr, BelongsTo } from "@temed/vue-jsonapi-orm";
import { ApiResourceWithDates } from "@/models/base/ApiResourceWithDates";

import { ChannelUser, Chat, ChatPlan } from "@/models/index";
import dayjs, {Dayjs} from "dayjs";
export class ChannelUserPlan extends ApiResourceWithDates {
    static jsonApiType = "channelUserPlans";

    @BelongsTo() chat?: Chat;
    @BelongsTo() channelUser?: ChannelUser;
    @BelongsTo() chatPlan?: ChatPlan;
    @Attr() active?: boolean;
    @Attr() stateCode?: string;
    @Attr() iteration?: number;
    @Attr() stepIn?: string;
    @Attr() stepOut?: string;
    @Attr() nextDate?: Dayjs;
    @Attr() nextStep?: string;
    @BelongsTo() returnChatPlan?: ChatPlan;
    @Attr() returnStep?: string;
    @Attr() prevChatPlanIds?: string[];

    get nextDateFormatted() {
        if (this.nextDate) {
            return dayjs(this.nextDate).format('DD.MM.YYYY, HH:mm:ss')
        }
        return undefined
    }

}