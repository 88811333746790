
import {Component} from "vue-property-decorator";
import DefaultDescriptor from "@/components/webtask/partial/DefaultDescriptor";

@Component
export default class TestTaskDescriptor extends DefaultDescriptor {

  mounted() {
    super.mounted();
    //console.log("[ TestTaskDescriptor ] channels: ", this.channels)
  }
}
