import { Attr, Meta, HasMany, BelongsTo } from "@temed/vue-jsonapi-orm";
import { ApiResourceWithDates } from "@/models/base/ApiResourceWithDates";
import {User} from "@/models/User";
export class Attachment extends ApiResourceWithDates {
    static jsonApiType = "attachments";

    @Meta() attachmentType?: string;
    @Meta() name?: string;
    @Meta() objectName?: string;
    @Meta() mediaType?: string;
    @Meta() key?: string;
    @Meta() etag?: string;
    @Meta() duration?: number;
    @Meta() width?: number;
    @Meta() height?: number;
    @Meta() size?: number;
    @Meta() telegramFileId?: string;
    @Attr() protectContent?: boolean;
    @Attr() useSpoiler?: boolean;
    @Attr() description?: string;
    @Attr() useMarkdown?: boolean;
    @BelongsTo() user?: User;
    @Meta() sortIndex?: number;
    @Meta() kind?: string;
    @Meta() pathPrefix?: string;
    @Meta() iconName?: string;
    @Meta() smallName?: string;
    @Meta() mediumName?: string;
    @Meta() largeName?: string;
    @Meta() thumbName?: string;

}