
import {Vue, Component, Emit, Prop} from 'vue-property-decorator';
import {Assistant} from "@/models";

@Component({})
export default class AssistantForm extends Vue {
  @Prop() private assistant?: Assistant;

  //mounted() {}

  get canSave() {
    if (!this.assistant) {
      console.log('no ass')
      return false
    }
    console.log('has ass')
    return !this.assistant.persisted || this.assistant.$isDirty
  }

  saveAssistant() {
    if (!this.assistant || !(!this.assistant?.persisted || this.assistant?.$isDirty)) return

    if (this.assistant.persisted) {
      this.assistant.save()
    }
    else {
      this.assistant.save()
          .then(assistant => {
            this.$router.push({name: 'assistantCard', params: {assistantId: assistant.id}})
          })
    }
  }

  deleteAssistant() {
    if (!this.assistant) return

    this.assistant.destroy()
        .then(() => {
          this.$router.push({ name: 'assistants' })
        })
  }

  private modelOptions = [
    { value: null, text: 'Выберите модель' },
    { value: 'gpt-4o', text: 'gpt-4o' },
    { value: 'gpt-4-turbo', text: 'gpt-4-turbo' },
    { value: 'gpt-4', text: 'gpt-4' },
    { value: 'gpt-4o-mini', text: 'gpt-4o-mini' },
    { value: 'gpt-3.5-turbo-0125', text: 'gpt-3.5-turbo-0125' },
    { value: 'gpt-3.5-turbo-1106', text: 'gpt-3.5-turbo-1106' },
    { value: 'gpt-3.5-turbo-0613', text: 'gpt-3.5-turbo-0613' }
  ]
}
