import {Attr, BelongsTo, Meta} from "@temed/vue-jsonapi-orm";
import { ApiResourceWithDates } from "@/models/base/ApiResourceWithDates";
import {Attachment, ChannelUser, Chat, Employee} from "@/models/index";
import { Message } from "@/models/types/TelegramApiTypes";
import {Actor} from "@/models/types/Types";
import {Assistant} from "@/models/Assistant";

export class ChatMessage extends ApiResourceWithDates {
    static jsonApiType = "chatMessages";

    @BelongsTo() chat?: Chat;
    @BelongsTo() channelUser?: ChannelUser;
    @Meta() actor?: Actor;
    @Meta() updateId?: number;
    @Meta() messageId?: number;
    @Meta() text?: string;
    @Meta() message?: Message;
    @BelongsTo() parent?: ChatMessage;
    @Meta() isCommand?: boolean;
    @Meta() stateCode?: string;
    @Meta() attachmentType?: string;
    @Meta() edited?: boolean;
    @Meta() className?: string;
    @Meta() mediaGroupId?: string;
    @BelongsTo() attachment?: Attachment;
    @BelongsTo() employee?: Employee;

    @BelongsTo() assistant?: Assistant
    @Meta() model?: string;
    @Meta() finishReason?: string;
    @Meta() promptTokens?: number;
    @Meta() competitionTokens?: number;
}