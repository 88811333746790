import { Meta, Attr, BelongsTo } from "@temed/vue-jsonapi-orm";
import { ApiResourceWithDates } from "@/models/base/ApiResourceWithDates";

import {Chat} from "@/models/Chat";
import {Employee} from "@/models/Employee";
import {BookmarkType} from "@/models/types/Types";
export class EmployeeBookmark extends ApiResourceWithDates {
    static jsonApiType = "employeeBookmarks";

    @BelongsTo() chat?: Chat
    @BelongsTo() employee?: Employee
    @Meta() bookmarkType?: BookmarkType
    @Attr() useNotify?: boolean

}