
import {Vue, Component, Prop, Watch} from 'vue-property-decorator';
import {ResourceCollection} from "@temed/vue-jsonapi-orm";
import {ChannelUser, UserAnswer} from "@/models";
import axiosInstance from "@/axios";
import IChartIntDataDto from "@/dto/IChartIntDataDto";
import ChartNumberDataItem from "@/dto/ChartNumberDataItem";
import VChart from "vue-echarts";
import dayjs from "dayjs";
import ISorterItem from "@/dto/ISorterItem";

@Component({
  components: {VChart}
})
export default class ChatAnswerReportPanel extends Vue {
  @Prop() channelUser?: ChannelUser | null
  @Prop({ default: 'level' }) valueName?: string | null

  private chartData: ChartNumberDataItem[] = [];
  private answerHistory?: ResourceCollection<UserAnswer> | null = null

  private isLoading = false;
  private isLoadDone = false;
  private hasError = false;
  private errorCode: number | undefined;
  private errorMessage: string | undefined;

  private sorters: ISorterItem[] = [
    {name: 'По убыванию даты ответа', sort: '-createdDate'},
    {name: 'По возрастанию даты ответа', sort: 'createdDate'},
    {name: 'По убыванию уровня боли', sort: '-numberValue,-createdDate'},
    {name: 'По возрастанию уровня боли', sort: 'numberValue,-createdDate'}
  ]

  private currentSorter: ISorterItem = this.sorters[0];


  mounted() {
    this.loadData()
    this.loadChartData()
  }

  @Watch("channelUser")
  onChannelUserChange() {
    this.loadData()
    this.loadChartData()
  }

  get channelUserId(): string | null {
    if (this.channelUser) {
      return this.channelUser.id
    }
    return null
  }
  get isDataReady(): boolean {
    return this.isLoadDone && this.chartData.length > 0;
  }
  private loadData() {
    if (!this.channelUser || !this.channelUserId || !this.valueName) {
      console.warn('Can`t load answer history for empty ChannelUser')
      return
    }

    this.answerHistory = new ResourceCollection(
        UserAnswer.api()
            .perPage(10)
            .filter({'channelUser': this.channelUser?.id})
            .orderByDesc("createdDate"),
        `answer-history-${this.channelUser.id}`
    )

    this.firstPage()
  }



  private loadChartData() {
    if (!this.channelUser) {
      console.warn('Can`t load chart data for empty ChannelUser')
      return
    }
    this.isLoading = true;
    axiosInstance.get(`/api/reports/chart/${this.channelUser.id}/level`)
        .then(response => {
          this.chartData = response.data.map((dto: IChartIntDataDto) => {
            return ChartNumberDataItem.of(dto)
          });
          this.isLoadDone = true;
          this.hasError = false;
        })
        .catch(error => {
          this.hasError = true;
          this.buildErrorData(error);
        })
        .finally(() => {
          this.isLoading = false;
        })
  }

  private buildErrorData(error: any) {
    this.hasError = true;
    this.errorCode = error?.response?.status;
    if (this.errorCode === 400) {
      this.errorMessage = error?.response?.data.detail;
    } else {
      this.errorMessage = error?.response?.statusText;
    }
  }

  get latestData(): string {
    if (!this.isDataReady) {
      return '-';
    }
    return this.chartData[this.chartData.length - 1]._date.format("DD.MM.YYYY");
  }

  get notNullChartData() {
    return this.chartData.filter(i => i.value != null);
  }

  get latest30DaysData() {
    if (this.notNullChartData.length < 30) {
      return this.notNullChartData;
    }
    return this.notNullChartData.slice(this.notNullChartData.length - 30, this.notNullChartData.length);
  }

  sortByValueAndDate = (data: ChartNumberDataItem[]) => {
    return data.sort((a, b) => {
      // Only sort on value if not identical
      if ((a.value ? a.value : 0) < (b.value ? b.value : 0)) return -1;
      if ((a.value ? a.value : 0) > (b.value ? b.value : 0)) return 1;
      // Sort on name
      if (a._date.isBefore(b._date)) return -1;
      if (a._date.isAfter(b._date)) return 1;
      // Both identical, return 0
      return 0;
    });
  }

  get minValueLatest30days() {
    if (this.latest30DaysData.length === 0) {
      return undefined;
    }
    const latestData = this.sortByValueAndDate([...this.latest30DaysData]);
    const minValue = latestData[0].value;
    const allMinValues = latestData.filter(i => i.value === minValue)
    return allMinValues[allMinValues.length - 1]
  }

  get maxValueLatest30days() {
    if (this.latest30DaysData.length === 0) {
      return undefined;
    }
    const latestData = this.sortByValueAndDate([...this.latest30DaysData]);
    return latestData[latestData.length - 1]
  }

  private colors: string[] = [
    '#0E850E',
    '#26910E',
    '#429C0D',
    '#62A90C',
    '#88B50B',
    '#B2C10A',
    '#CEBA08',
    '#DB9E06',
    '#E87D05',
    '#F55702',
    '#FF2D04',
  ];

  get buildChatOption(): any {
    const {code} = this.$route.params;
    const reportDate = dayjs().format("DD.MM.YYYY, HHmmss")
    return {
      toolbox: {
        feature: {
          dataZoom: {
            yAxisIndex: 'none',
            title: {
              zoom: 'Выбрать область',
              back: 'Сбросить зум'
            }
          },
          restore: {
            title: 'Восстановить исходное состояние'
          },
          saveAsImage: {
            name: `График ВАШ [${code}], ${reportDate}`,
            title: 'Сохранить график',
            excludeComponents: ['dataZoom', 'toolbox'],
            pixelRatio: 2
          }
        }
      },
      xAxis: {
        type: 'time',
        // boundaryGap: false,
        // scale: true,
        axisLabel: {
          rotate: 0,
          showMinLabel: true,
          showMaxLabel: true,
          formatter: function (value: Date) {
            const date = dayjs(value);
            return date.format('DD.MMM')
          },
          hideOverlap: true,
        },
      },
      yAxis: {
        type: 'value',
        boundaryGap: [0, '30%'],
        max: 10,
        min: 0,
        name: 'Уровень боли по ВАШ',
        nameLocation: 'middle',
        nameGap: 40
      },
      tooltip: {
        trigger: 'axis',
        formatter: function (params: any) {
          const date = dayjs(params[0].data[0]);
          let output = `<div style="text-align: left">`;
          output += `<div style="text-align: right;">${date.format('dd, HH:mm, <b>DD.MM.YYYY</b>')}</div>`;
          // output += `<div  style="text-align: right; font-weight: bold;">${date.format('dddd')}</div>`;
          for (let i = 0; i < params.length; i++) {
            output += `<div style="white-space: nowrap;">${params[i].marker} ${params[i].seriesName}:&nbsp;<div style="display: inline-block; float: right; font-weight: 600;">${params[i].value[1]}</div></div>`;
          }
          output += `</div>`;
          return output
        }

      },
      dataZoom: [
        {
          start: this.notNullChartData.length < 30 ? 0 : 80
        },
        {
          type: 'inside'
        }
      ],
      visualMap: {
        show: false,
        top: 32,
        right: 70,
        type: 'continuous',
        seriesIndex: 0,
        min: 0,
        max: 10,
        text: ['10', '0'],
        inRange: {
          color: ['#0E850E', '#88B50B', '#CEBA08', '#E87D05', '#FF2D04'],
          symbolSize: [6, 18]
        },
        dimension: 1
      },
      series: [
        {
          name: 'ВАШ',
          type: 'line',
          smooth: 0.6,
          symbol: 'circle',
          symbolSize: 6,
          showAllSymbol: false,
          connectNulls: true,
          lineStyle: {
            // color: '#5470C6',
            width: 3
          },
          label: {
            // show: true,
            position: 'top'
          },
          markLine: {
            symbol: ['none', 'none'],
            silent: true,
            animation: false,
            lineStyle: {
              type: 'dashed',
              color: "#cccccc"
            },
            label: {
              show: true,
              position: 'insideEndTop',
              formatter: function (params: any) {
                const date = dayjs(params.value);
                return date.format('MMM, YYYY')
              }
            },
            data: [
              ...this.getMonthLineMarks(this.chartData),
            ]
          },
          areaStyle: {
            opacity: 0.5
          },
          // data: this.chartData.map(({date, value}) => { return [date.toDate(), value]})
          data: this.chartData.map(i => [i.date, i.value])
        }
      ]
    }
  }

  getMonthLineMarks = (data: ChartNumberDataItem[]) => {
    let month = -1;
    const lineMarks: any[] = [];
    data.forEach(item => {
      if (month !== item._date.month()) {
        month = item._date.month();
        lineMarks.push({xAxis: item.date})
      }
    })
    if (lineMarks.length > 1) {
      return lineMarks.splice(1, lineMarks.length - 1);
    }
    return lineMarks;
  }


  /*
  * Подготовка url`a для загрузки истории ответов
  * */
  buildLogHistoryUrl(page: number, size: number, sort: string, channelUserId: string, valueName: string) {
    return `/api/userAnswers/history/${channelUserId}/${valueName}?page[number]=${page}&page[size]=${size}&sort=${sort}`;
  }

  buildFirstPageUrl(size: number, sort: string, channelUserId: string, valueName: string) {
    return this.buildLogHistoryUrl(1, size, sort, channelUserId, valueName);
  }


  firstPage() {
    if (!this.answerHistory) return;

    if (this.answerHistory.paginationMeta && this.answerHistory.pagination && !this.answerHistory.$isFirstPage) {
      this.answerHistory.pagination = {
        ...this.answerHistory.pagination,
        current_page: 1,
      }
    }
    if (this.channelUserId && this.valueName) {
      this.answerHistory.requestItems({
        url: this.buildLogHistoryUrl(
            1,
            10,
            this.currentSorter.sort,
            this.channelUserId,
            this.valueName
        )
      })
    }
    this.scrollToHistoryHeader();
  }

  prevPage() {
    if (this.answerHistory && this.answerHistory.paginationMeta && this.answerHistory.pagination && !this.answerHistory.$isFirstPage) {
      this.answerHistory.pagination = {
        ...this.answerHistory.pagination,
        current_page: this.answerHistory.pagination.current_page - 1,
      }
      this.loadPage()
      this.scrollToHistoryHeader();
    }
    return;
  }

  loadPage() {
    if (this.answerHistory && this.answerHistory.pagination && this.answerHistory.paginationMeta && this.channelUserId && this.valueName) {
      this.answerHistory.requestItems({
        url: this.buildLogHistoryUrl(
            this.answerHistory.pagination.current_page,
            this.answerHistory.paginationMeta.size,
            this.currentSorter.sort,
            this.channelUserId,
            this.valueName
        )
      })
    }
  }

  lastPage() {
    if (this.answerHistory && this.answerHistory.paginationMeta && this.answerHistory.pagination && !this.answerHistory.$isLastPage) {
      this.answerHistory.pagination = {
        ...this.answerHistory.pagination,
        current_page: this.answerHistory.paginationMeta.totalPages,
      }
      this.loadPage()
      this.scrollToHistoryHeader();
    }
    return;
  }

  nextPage() {
    if (this.answerHistory && this.answerHistory.paginationMeta && this.answerHistory.pagination && !this.answerHistory.$isLastPage) {
      this.answerHistory.pagination = {
        ...this.answerHistory.pagination,
        current_page: this.answerHistory.pagination.current_page + 1,
      }
      this.loadPage()
      this.scrollToHistoryHeader();
    }
    return;
  }

  setCurrentSorter(newSorter: ISorterItem) {
    this.currentSorter = newSorter;
    if (!this.answerHistory) return

    if (this.channelUserId && this.valueName) {
      this.firstPage()
    }
  }

  scrollToHistoryHeader() {
    this.$nextTick(() => {
      const el = document.getElementById('log-history-header');
      if (el) {
        el.scrollIntoView({ behavior: "smooth" })
      }
    });
  }

  getOffsetTop = (element: any): number => {
    return element ? (element.offsetTop + this.getOffsetTop(element.offsetParent)) : 0;
  }

}
