
import {Component, Prop, Vue} from "vue-property-decorator";
import {ChannelType, FaIconSize} from "@/models/types/Types";

@Component
export default class ChannelTypeIcon extends Vue {
  @Prop() type!: ChannelType
  @Prop({ default: "lg" }) size!: FaIconSize

  get isTelegram() {
    return this.type === ChannelType.TELEGRAM
  }
  get isWhatsapp() {
    return this.type === ChannelType.WHATSAPP
  }
  get isWechat() {
    return this.type === ChannelType.WECHAT
  }
}
