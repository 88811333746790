
import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import {ChatMessage} from "@/models/ChatMessage";
import {Actor} from "@/models/types/Types";
import {Message} from "@/models/types/TelegramApiTypes";

@Component
export default class ChatMessageCard extends Vue {
  @Prop() private message!: ChatMessage
  @Prop({ default: false }) private alignLeft!: boolean

  private leftPosition = [Actor.USER, Actor.CALLBACK, Actor.USER_ACTION]

  mounted() {
    //console.log('[ ChatMessageCard#mount ] message:', this.message)
    //this.message.load('attachment')
  }

  @Emit()
  onReplyTo(message: ChatMessage) {
    return message
  }
  get messageText() {
    if (!this.message || !this.message.text) {
      return undefined
    }
    return this.message.text.replace(/\n/g, "<br />")
  }

  get isCallback() {
    return this.message && this.message.actor === Actor.CALLBACK
  }

  get buttonText() {
    if (!this.message || !this.message.text) {
      return undefined
    }
    return this.message.text.replace("button: ", "")
  }

  get isReply(): boolean {
    return (this.message && !!this.message.message && !!this.message.message.reply_to_message)
  }

  get replyMessage(): Message | undefined {
    if (!this.isReply) {
      return undefined
    }
    return this.message.message?.reply_to_message
  }
  get isUserMessage() {
    return this.message && this.message.actor && this.leftPosition.includes(this.message.actor)
  }

  get previewImageUrl() {
    if (this.message && this.message.attachment) {
      return `/api/directMedia/${this.message.attachment.id}/preview`
    }
    return undefined
  }
  get defaultMediaUrl() {
    if (this.message && this.message.attachment) {
      return `/api/directMedia/${this.message.attachment.id}/default`
    }
    return undefined
  }

  get isAudio() {
    return this.message
        && this.message.attachment
        && (this.message.attachment.attachmentType === 'AUDIO'
            || this.message.attachment.attachmentType === 'VOICE')
  }
  get isVideoNote() {
    return this.message
        && this.message.attachment
        && this.message.attachment.attachmentType === 'VIDEO_NOTE'
  }
  get isVideo() {
    return this.message
        && this.message.attachment
        && ( this.message.attachment.attachmentType === 'VIDEO_NOTE'
            || this.message.attachment.attachmentType === 'VIDEO')
  }
  get isDocument() {
    return this.message
        && this.message.attachment
        && this.message.attachment.attachmentType === 'DOCUMENT'
  }

  get hasPreview() {
    return this.message
        && this.message.attachment
        && this.message.attachment.thumbName
  }
}
