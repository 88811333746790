
import {Vue, Component, Emit, Prop} from 'vue-property-decorator';

@Component({})
export default class TRightSidePanel extends Vue {
  @Prop() title?: string
  @Prop() subTitle?: string
  @Emit()
  onClose() {
    console.log('fire close panel')
    return false
  }
}
