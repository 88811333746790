import { Attr, Meta, HasMany, BelongsTo } from "@temed/vue-jsonapi-orm";
import { ApiResourceWithDates } from "@/models/base/ApiResourceWithDates";
import {Employee} from "@/models/Employee";
import {Channel} from "@/models/Channel";
import {ProjectDelegate} from "@/models/ProjectDelegate";
import {ProjectState} from "@/models/types/Types";
export class Project extends ApiResourceWithDates {
    static jsonApiType = "projects";

    @Attr() name?: string;
    @Attr() description?: string;
    @Attr() state?: ProjectState;
    @BelongsTo() owner?: Employee;
    @Meta() accessLevel?: string;
    @HasMany() projectDelegates?: ProjectDelegate[];
    @HasMany() channels?: Channel[];

}