
import {Vue, Component, Prop} from 'vue-property-decorator';
import ISorterItem from "@/dto/ISorterItem";
import {ResourceCollection} from "@temed/vue-jsonapi-orm";
import {UserAnswer} from "@/models";

@Component({})
export default class AnswerHistory extends Vue {
  @Prop() apiKey?: string
  @Prop() channelUserId?: string
  @Prop() valueName?: string

  private pageSize = 10;
  private logHistory = new ResourceCollection(
      UserAnswer.api()
          .perPage(this.pageSize)
          .orderByDesc("createdDate")
  );


  mounted() {
    if (this.apiKey && this.channelUserId && this.valueName) {
      this.logHistory.requestItems({
        url: this.buildFirstPageUrl(this.pageSize, this.currentSorter.sort)
      })
    }
  }


  private sorters: ISorterItem[] = [
    {name: 'По убыванию даты ответа', sort: '-createdDate'},
    {name: 'По возрастанию даты ответа', sort: 'createdDate'},
    {name: 'По убыванию уровня боли', sort: '-numberValue,-createdDate'},
    {name: 'По возрастанию уровня боли', sort: 'numberValue,-createdDate'}
  ]

  private currentSorter: ISorterItem = this.sorters[0];

  private colors: string[] = [
    '#0E850E',
    '#26910E',
    '#429C0D',
    '#62A90C',
    '#88B50B',
    '#B2C10A',
    '#CEBA08',
    '#DB9E06',
    '#E87D05',
    '#F55702',
    '#FF2D04',
  ];

  setCurrentSorter(newSorter: ISorterItem) {
    this.currentSorter = newSorter;
    this.logHistory.pagination = {
      ...this.logHistory.pagination,
      current_page: 1,
    }
    this.logHistory.requestItems({url: this.buildFirstPageUrl(10, this.currentSorter.sort)})
  }

  buildLogHistoryUrl(page: number, size: number, sort: string) {
    return `/api/userAnswers/report/external/${this.apiKey}:${this.channelUserId}:${this.valueName}?page[number]=${page}&page[size]=${size}&sort=${sort}`;
  }

  buildFirstPageUrl(size: number, sort: string) {
    return this.buildLogHistoryUrl(1, size, sort);
  }

  firstPage() {
    if (this.logHistory.paginationMeta && this.logHistory.pagination && !this.logHistory.$isFirstPage) {
      this.logHistory.pagination = {
        ...this.logHistory.pagination,
        current_page: 1,
      }
      this.logHistory.requestItems({
        url: this.buildLogHistoryUrl(
            this.logHistory.pagination.current_page,
            this.logHistory.paginationMeta.size,
            this.currentSorter.sort
        )
      })
      this.scrollToHistoryHeader();
    }
    return;
  }

  lastPage() {
    if (this.logHistory.paginationMeta && this.logHistory.pagination && !this.logHistory.$isLastPage) {
      this.logHistory.pagination = {
        ...this.logHistory.pagination,
        current_page: this.logHistory.paginationMeta.totalPages,
      }
      this.logHistory.requestItems({
        url: this.buildLogHistoryUrl(
            this.logHistory.pagination.current_page,
            this.logHistory.paginationMeta.size,
            this.currentSorter.sort
        )
      })
      this.scrollToHistoryHeader();
    }
    return;
  }

  nextPage() {
    if (this.logHistory.paginationMeta && this.logHistory.pagination && !this.logHistory.$isLastPage) {
      this.logHistory.pagination = {
        ...this.logHistory.pagination,
        current_page: this.logHistory.pagination.current_page + 1,
      }
      this.logHistory.requestItems({
        url: this.buildLogHistoryUrl(
            this.logHistory.pagination.current_page,
            this.logHistory.paginationMeta.size,
            this.currentSorter.sort
        )
      });
      this.scrollToHistoryHeader();
    }
    return;
  }

  prevPage() {
    if (this.logHistory.paginationMeta && this.logHistory.pagination && !this.logHistory.$isFirstPage) {
      this.logHistory.pagination = {
        ...this.logHistory.pagination,
        current_page: this.logHistory.pagination.current_page - 1,
      }
      this.logHistory.requestItems({
        url: this.buildLogHistoryUrl(
            this.logHistory.pagination.current_page,
            this.logHistory.paginationMeta.size,
            this.currentSorter.sort
        )
      })
      this.scrollToHistoryHeader();
    }
    return;
  }


  scrollToHistoryHeader() {
    this.$nextTick(() => {
      const el = document.getElementById('log-history-header');

      if (el) {
        window.scrollTo({
          top: this.getOffsetTop(el) - 16,
          left: 0,
          behavior: "smooth",
        });
      }
    });
  }

  getOffsetTop = (element: any): number => {
    return element ? (element.offsetTop + this.getOffsetTop(element.offsetParent)) : 0;
  }
}
