
import { Component, Vue, Prop } from 'vue-property-decorator';
import { User } from "@/models/User";
import { Comment } from "@/models/Comment";
import { ResourceCollection } from "@temed/vue-jsonapi-orm";
import { Dayjs } from "dayjs";
import { Employee } from "@/models/Employee";


@Component
export default class AboutView extends Vue {
  @Prop({default: 'X'}) someProp?: string;
  private collection = new ResourceCollection(User.api())
  private employees = new ResourceCollection(
      Employee.api()
          .perPage(30)
          .orderBy('lastName,firstName')
  )
  private comments = new ResourceCollection(
      Comment.api()
          .with('author')
          .with('parent')
          .perPage(5)
          .page(1)
          .orderBy('createdDate', "asc")
  )

  private someDate = "2022-12-12T13:54:47.859+00:00";
  private dayjsDate: Dayjs = this.$dayjs(this.someDate)

  mounted() {
    ////console.log("[ AboutView::mounted ]")
    this.employees.requestItems();
  }

  getParentId(comment?: Comment) {
    return comment?.parent?.id
  }

  playSound() {
    const sound = new Audio('/sound/bell.mp3');
    sound.play()
  }

  loadUsers(): void {
    ////console.log("[ AboutView::loadUsers ] ...")
    User.requestActiveUser().then(response => {
      ////console.log("[ AboutView::loadUsers ] response:", response)
    })
  }

  userLoad(): void {
    ////console.log("[ AboutView::userLoad ] ...")
    User.api().find('1001').then(r => {
      ////console.log("[ AboutView::userLoad ] result: ", r)
    })

  }

  async createUser() {
    let user = new User({
      name: "Frodo Baggins",
      email: "baggins@me.com",
      userName: "frodo"
    });
    user.lastName = 'Baggins';

    ////console.log("[ AboutView::createUser ] user: ", user)
    let result = await user.save()
    ////console.log("[ AboutView::createUser ] result: ", result)
  }

  loadCollection(): void {
    this.collection.requestItems({url: '/api/users'});
  }

  loadCommentsPage(): void {
    this.comments.requestItems()
  }

  loadAuthor(comment: Comment) {
    ////console.log("[ AboutView::loadAuthor ] comment: ", comment)
    comment.load('author').then((data: any) => {
      ////console.log("[ AboutView::loadAuthor ] loaded data: ", data)
    })
  }

  //*** Create Employee
  private newEmployee: Employee | null = null
  createEmployee() {
    this.newEmployee = new Employee();
  }
  cancel() {
    this.newEmployee = null;
  }

  saveEmployee() {
    this.newEmployee?.save().then(() => {
      this.employees.requestItems();
      this.cancel()
    })
  }

  //*** Create Comment
  private newComment: Comment | null = null
  createComment() {
    this.newComment = new Comment();
  }
  cancelComment() {
    this.newComment = null
  }
  saveComment() {
    this.newComment?.save().then(() => {
      this.cancelComment()
    })
  }

  onAuthorInput(author: Employee) {
    ////console.log('{ onAuthorInput } author: ', author)
    if (this.newComment != null) {
      this.newComment.author = author
    }
  }

  //***
  logout() {
    if (this.$keycloak.ready && this.$keycloak.authenticated && this.$keycloak.logoutFn) {
      this.$keycloak.logoutFn({ redirectUrl: "http://localhost:8081" })
    }
    else {
      ////console.log("[ logout ? ]", this.$keycloak.logoutFn)
    }
  }

  updateToken() {
    ////console.log(this.$keycloak.tokenParsed?.exp, this.$keycloak.tokenParsed?.iat, this.$keycloak.tokenParsed?.auth_time);
    this.$keycloak?.keycloak?.updateToken(18000).then(function(refreshed) {
      if (refreshed) {
        ////console.log('Token was successfully refreshed');
      } else {
        ////console.log('Token is still valid');
      }
    }).catch(function() {
      ////console.log('Failed to refresh the token, or the session has expired');
    });
  }
}
