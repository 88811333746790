
import {Vue, Component, Prop, Emit} from 'vue-property-decorator';

@Component({})
export default class TParamFlexDisplay extends Vue {
  @Prop() title?: string
  @Prop() readonly value?: any
  @Prop({ default: false, type: Boolean }) editable?: boolean
  @Prop({ default: false, type: Boolean }) editMode?: boolean
  @Prop({ default: false, type: Boolean }) isDirty?: boolean
  @Prop({ default: false, type: Boolean }) canSetNull?: boolean
  @Prop({ default: 'text', type: String }) type?: string

  get valueOrHyphen() {
    if (typeof this.value === 'undefined' || this.value === null) {
      return '—'
    }
    return this.value
  }

  get valueToEdit() {
    return this.value
  }
  set valueToEdit(value: any) {
    console.log('{ valueToEdit => }', value)
    this.onChange(value)
  }

  @Emit()
  onChange(value: any) {
    console.log('{ file onChange => }', value)
    return value
  }

  @Emit()
  onRollback() {
    console.log('{ fire rollback}')
  }
}
