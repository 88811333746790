
import {Vue, Component, Prop, Watch} from 'vue-property-decorator';
import ChatList from "@/components/chat/ChatList.vue";
import {Channel, Chat} from "@/models";
import ChatMessages from "@/components/chat/ChatPanel.vue";
import {ResourceCollection} from "@temed/vue-jsonapi-orm";
import { Route } from "vue-router";
import ChatList2 from "@/components/chat/ChatList2.vue";
import AuthAlert from "@/components/AuthAlert.vue";

@Component({
  components: {AuthAlert, ChatList2, ChatMessages, ChatList}
})
export default class ChatsView extends Vue {
  @Prop() private channelName!: string
  @Prop() private chatId!: string
  private selectedChat: Chat | null = null
  private channel: Channel | null = null
  private chatListReady = false
  private onlyAttention = false

  private searchText: string | null = null
  private searchTextMinLength = 3

  private notifyGranted = false

  get channelNameWithStaticNames(): string | null {
    switch (this.channelName) {
      case 'bookmarks': return 'Закладки'
      case 'conversations': return 'Мои переписки'
      default: return this.channelName
    }
  }
  created() {
    const { search } = this.$route.query
    //console.log('[ ChatsView::created ] channelName: ' + this.channelName + ', chatId: ' + this.chatId + ', search: ' + search);

    if (search) {
      this.searchText = search as string
    }
  }

  mounted() {
    const { search } = this.$route.query
    //console.log('[ ChatsView::mounted ] channelName: ' + this.channelName + ', chatId: ' + this.chatId + ', search: ' + search);

    // if (search) {
    //   this.searchText = search as string
    // }

    // this.loadChannelByName()
    this.initWebNotify();
  }

  initWebNotify() {
    Notification.requestPermission((result) => {
      //console.log("{ Notification.requestPermission } : ", result)
      if (result === 'granted') {
        this.notifyGranted = true
      }
    })
  }

  showWebNotify() {
    //console.log('showNotify')
    if (!this.notifyGranted) return

    const notifyId = 'n-' + Math.random()

    const notify = new Notification("Тестовое уведомление", {
      tag: notifyId,
      body: notifyId + ' Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
      icon: 'https://x1.stage.chatplant.ru/img/chat-plant-logo-512.png',
      image: 'https://x1.stage.chatplant.ru/img/chat-plant-logo-512.png',
    })
    notify.onshow = this.onNotifyShow
    //console.warn("{ showNotify }: ", notify)
    // if (notify) {
    //   notify.onshow((ntf) => {
    //     //console.log('{ notify: onShow} ', ntf)
    //   })
    // }
  }

  onNotifyShow(event: Event) {
    //console.warn('{ onNotifyShow }', event)
  }

  // @Watch("channelName")
  // onChannelChange() {
  //   //console.log('[ ChatsView::onChannelChange ] load channel page by channelName: ' + this.channelName);
  //   this.loadChannelByName()
  // }

  loadChannelByName() {
    if (this.channelName) {
      //console.log('[ ChatsView::mounted ] load channel page by channelName: ' + this.channelName);
      const tmpChannels = new ResourceCollection(
          Channel.api().filter({'userName': this.channelName}),
          `tmp-channels-by-name-${this.channelName}`
      )
      tmpChannels.requestItems().then(items => {
        //console.log('[ ChatsView::mounted ] load channel page by channelName | result: ', items);
        if (items && items.length) {
          this.channel = items[0]
        }
      })
    }
  }

  onChatSelect(chat: Chat) {
    this.selectedChat = chat
    //console.log('[ ChatList#onChatSelect ] to chat: ', chat.id)

    if (this.$route.name) {
      if (['allChats', 'allChatsChat'].includes(this.$route.name)) {
        this.$router.push({name: 'allChatsChat', params: {chatId: chat.id}})
      }
      else if (['chatsByChannel', 'chatsByChannelChat'].includes(this.$route.name)) {
        this.$router.push({name: 'chatsByChannelChat', params: {chatId: chat.id}})
      }
    }
    // //console.log('[ ChatList#onChatSelect ] receive: ', chat)
  }

  @Watch("$route.name")
  onRouteNameChange() {
    if (this.$route.name && ['allChats', 'chatsByChannel'].includes(this.$route.name)) {
      this.selectedChat = null;
    }
  }

  onChatOpen(chat: Chat) {
    //console.log('[ ChatView#onChatOpen ] receive emitted chat: ', chat)
    if (chat != null && (!this.selectedChat || this.selectedChat.id !== chat.id)) {
      this.selectedChat = chat
    }
  }

  @Watch("$route")
  onHashChange(to: Route) {
    const { search } = to.query
    //console.log('[ ChatView#onHashChange ] search: ', search)
    if (!this.searchText || this.searchText.trim() !== search) {
      if (search) {
        this.searchText = search as string
      }
    }
  }
  onSearchTextInput(searchText: string | null) {
    //console.log('[ ChatView#onSearchTextInput ] searchText: ', searchText)

    if (!searchText) {
      this.$router.push({ path: this.$route.path, query: {} })
    }

    const { search } = this.$route.query
    if ( searchText && search !== searchText.trim() &&  searchText.trim().length >= this.searchTextMinLength) {
      this.$router.push({ path: this.$route.path, query: { search: searchText.trim() } })
    }
  }

  clearSearch() {
    this.searchText = null;
    this.$router.push({ path: this.$route.path, query: {} })
  }

  get trimmedSearchString() {
    if (this.searchText && this.searchText.trim().length >= this.searchTextMinLength) {
      return this.searchText.trim()
    }
    return null
  }

  get searchStringValidate() {
    if (!this.searchText) {
      return null
    }
    return this.searchText.trim().length  >= this.searchTextMinLength ? null : false
  }
}
