
import {Component, Prop, Vue} from "vue-property-decorator";
import {Channel, ChannelUser} from "@/models";
import {ResourceCollection} from "@temed/vue-jsonapi-orm";
import dayjs from "dayjs";
import UserAvatar from "@/components/user/UserAvatar.vue";

@Component({
  components: {UserAvatar}
})
export default class ChannelUserTable extends Vue {
  @Prop() channel!: Channel

  private channelUsers = new ResourceCollection<ChannelUser> (
      ChannelUser.api()
          .with(['user', 'profile'])
          .page(1)
          .perPage(50)
          .filter({channel: this.channel.id}),
      `channelUsers-channel-${this.channel.id}`
  )

  private fields = [
    {
      key: 'userName',
      sortable: true,
      //thClass: ['align-middle']
    },
    {
      key: 'sourceTag',
      sortable: true
    },
    {
      key: 'tariff',
      sortable: true
    },
    {
      key: 'scenarioState',
      sortable: true
    },
    {
      key: 'state',
      sortable: true,
      formatter: (value: any, key: any, item: any) => {
        return `${item.prevState ? item.prevState + ' › ' : ''}${item.state}`
      }
    },
    {
      key: 'registerDate',
      formatter: this.dayjsFormatter,
      sortable: true,
      label: 'Дата регистрации'
    },
    {
      key: 'lastAction',
      formatter: this.dayjsFormatter,
      sortable: true,
      label: 'Дата последнего действия'
    }
  ]

  mounted() {
    this.init();
  }

  //@Watch('channel')
  init() {
    this.channelUsers.setFilter({channel: this.channel.id})
  }

  get items() {
    if (!this.channelUsers.items) return []
    return this.channelUsers.items.map(item => {
      return {
        id: item.id,
        userId: item.user?.id,
        isPremium: item.user?.isPremium,
        userFullName: `${item.user?.firstName ? item.user.firstName : ''} ${item.user?.lastName ? item.user.lastName : ''}`,
        userName: item.user?.userName ? '@' + item.user.userName : null,
        ...item.attributes,
        ...item.$original?.meta,
        profile: item.profile ? { id: item.profile.id, ...item.profile.attributes, ...item.profile.$original } : {}
      }
    })
  }

  dayjsFormatter(value: any, key: string, item: {[key: string]: any}) {
    return value ? dayjs(value).format("DD.MM.YYYY, HH:mm:ss") : null
  }
}
