
import {Component, Prop, Vue} from "vue-property-decorator";
import {ResourceCollection} from "@temed/vue-jsonapi-orm";
import {ChannelUser} from "@/models";

@Component
export default class ChannelUserCounterBadge extends Vue {
  @Prop() private channelId!: string;
  private channelUsers = new ResourceCollection<ChannelUser>(
      ChannelUser.api().page(1).perPage(1),
      `tmp-${this.channelId}`
  )

  mounted() {
    //console.log('[ ChannelUserCounterBadge::mounted ]')
    this.loadData()
  }

  loadData() {
    //console.log('[ ChannelUserCounterBadge::loadData ] channel id', this.channelId)
    if (!this.channelId) return;
    this.channelUsers = new ResourceCollection<ChannelUser>(
        ChannelUser.api().page(1).perPage(1),
        `tmp-${this.channelId}`
    )
    //console.log('[ ChannelUserCounterBadge::loadData ] load first page with 1 record')
    this.channelUsers.setFilter({channel: this.channelId})
  }

}
