
import {Vue, Component, Prop} from 'vue-property-decorator';
import {ChannelUser, Chat, Profile, User} from "@/models";
import UserAvatar from "@/components/user/UserAvatar.vue";
import {Dayjs} from "dayjs";


@Component({
  components: {UserAvatar}
})
export default class ChannelUserProfileItem extends Vue {
  @Prop() modifiedDate!: Dayjs
  @Prop() private channelUser!: ChannelUser
  @Prop() private chat!: Chat
  private isLoading = false
  mounted() {
    this.loadData()
    //console.log('[ ChannelUserProfileItem#mounted ]', this.channelUser)
  }

  // updated() {
  //   //console.log('[ ChannelUserProfileItem#updated ] channelUserId', this.channelUser?.id)
  //   //this.loadData()
  // }

  get user(): User | undefined {
    return this.channelUser?.user
  }
  get profile(): Profile | undefined {
    return this.channelUser?.profile
  }

  loadData() {
    if (!this.channelUser) return
    this.isLoading = true
    const loadRelations = [
      this.channelUser.load('user'),
      this.channelUser.load('profile', ['doctor', 'clinic']),
      this.channelUser.load('employee')
    ]
    if (this.chat && !this.chat.latestChatMessage) {
      loadRelations.push(this.chat.load('latestChatMessage'))
    }
    Promise.all(loadRelations).finally(() => { this.isLoading = false })
  }
}
