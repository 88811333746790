
import {Vue, Component, Prop, Watch} from 'vue-property-decorator';
import {BookClient, ChannelUserPlan, Chat, Profile} from "@/models";
import {ProfileSyncStatus} from "@/models/types/Types";
import TParamFlexDisplay from "@/components/lib/TParamFlexDisplay.vue";

@Component({
  components: {TParamFlexDisplay}
})
export default class ChatDetailPanel extends Vue {
  @Prop() chat?: Chat
  @Prop() itemsCount?: number
  @Prop() totalItemsCount?: number
  private bookClient: BookClient | null = null

  private channelUserPlansLoading = false
  private channelUserPlansHasLoad = false
  private bookClientLoading = false
  private bookClientHasLoad = false
  private syncInProgress = false

  private chatEditMode = false

  mounted() {
    this.loadData()
  }

  @Watch("chat")
  private onChatChange() {
    this.loadData()
  }

  private loadData() {
    this.loadBookClient()
    this.loadChannelUserPlans()
  }

  get channelUserPlan(): ChannelUserPlan | null {
    if (this.chat && this.chat.channelUserPlans?.length) {
      return this.chat.channelUserPlans[0]
    }
    return null
  }

  get iconUrl() {
    if (this.chat && this.chat.channelUser && this.chat.channelUser.user?.profilePhoto?.iconName) {
      return `/api/directMedia/profile/${this.chat.channelUser.user.id}/medium`
    }
    return undefined
  }

  loadChannelUserPlans() {
    if (this.chat && this.chat.channelUser) {
      this.channelUserPlansLoading = true
      this.channelUserPlansHasLoad = false
      this.chat.channelUser
          .load('channelUserPlans', ['chatPlan'])
          .then(() => {
            this.channelUserPlansHasLoad = true
          })
          .catch(() => {
            this.channelUserPlansHasLoad = false
          })
          .finally(() => {
            this.channelUserPlansLoading = false
          })
    }
  }

  loadBookClient() {
    if (this.chat?.channelUser?.profile?.extId) {
      this.bookClientHasLoad = false
      this.bookClientLoading = true
      BookClient.api()
          .find(this.chat.channelUser.profile.extId)
          .then((bookClient) => {
            this.bookClient = bookClient
            this.bookClientHasLoad = true
          })
          .finally(() => {
            this.bookClientLoading = false
          })
    }
    else {
      console.warn('not enough data for load bookClient ')
    }
  }

  syncProfile(profile: Profile): Promise<Profile> {
    profile.syncStatus = ProfileSyncStatus.TO_SYNC;
    return profile.save()
  }

  syncAndReloadProfile(profile: Profile) {
    this.syncInProgress = true
    this.syncProfile(profile)
        .then(() => {
          //console.log('sync profile result: ', resp)
          this.loadBookClient()
        })
        .finally(() => this.syncInProgress = false)
  }

  get clinic() {
    return this.chat?.channelUser?.profile?.clinic?.name
  }

  stateHistoryBadgeVariant(state: string) {
    if (state) {
      switch (state) {
        case 'Отказ от лечения': return 'danger';
        case 'Завершил курс': return 'primary'
        case 'Пропустил лечение': return 'warning'
        case 'Лечение завершено': return 'primary'
        case 'Проходит лечение': return 'success'
        case 'RETURN': return 'primary'
        case 'Лечение показано': return 'info'
      }
    }
    return 'light'
  }

  /* edit fields */
  onChatTimeoutChange(value: number | undefined) {
    console.log('{ receive }', value)
    if (this.chat) {
      this.chat.timeout = value
      console.log('{ ?? } dirty: ', this.chat.$isDirty)
    }
  }


  editChat() {
    this.chatEditMode = true
  }
  saveChat() {
    this.chat?.save()
        .then(() => {
          this.chatEditMode = false
        })
  }

  rollbackChat() {
    if (this.chat?.$isDirty) {
      this.chat.$_rollbackChanges()
    }
    this.chatEditMode = false
  }
}
