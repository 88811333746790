
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {User} from "@/models";

@Component
export default class UserAvatar extends Vue {
  @Prop() private userId!: string;
  @Prop() private user!: User;
  @Prop({ default: false }) private attention!: boolean

  mounted() {
    this.init()
  }

  get iconUrl() {
    if (this.user && this.user.profilePhoto && this.user.profilePhoto.iconName) {
      return `/api/directMedia/profile/${this.user.id}/icon`
    }
    return undefined
  }

  get nameShortCut() {
    if (this.iconUrl) return undefined

    const items = [];
    if (this.user?.firstName) items.push(this.user.firstName)
    if (this.user?.lastName) items.push(this.user.lastName)
    const fullName = items.join(" ")

    if (!fullName.length) return undefined

    const partial = fullName.split(" ")
    if (partial.length == 1) {
      return partial[0][0].toUpperCase()
    }
    return partial[0][0].toUpperCase() + partial[partial.length-1][0].toUpperCase()
  }

  init() {
    if (this.userId) {
      this.user = User.fromId(this.userId);
    }

    if (this.user && !this.user.profilePhoto) {
      this.loadProfilePhoto()
    }
  }

  @Watch('user')
  onUserChange() {
    if (this.user && !this.user.profilePhoto) {
      this.loadProfilePhoto()
    }
  }

  loadProfilePhoto() {
    this.user.load('profilePhoto')
  }
}
