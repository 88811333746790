
import {Component, Prop, Ref, Vue, Watch} from 'vue-property-decorator';
import {BookClient, ChannelUserPlan, Chat, Profile} from "@/models";
import {StompService} from "@/services/StompService";
import {ResourceCollection} from "@temed/vue-jsonapi-orm";
import {ChatMessage} from "@/models/ChatMessage";
import ChatMessageCard from "@/components/chat/ChatMessageCard.vue";
import {byPropertiesOf} from "@/types/sorter";
import UserAvatar from "@/components/user/UserAvatar.vue";
import {Actor, ProfileSyncStatus} from "@/models/types/Types";
import {StompSubscription} from "@/types/StompTypes";
import {TextMessageDto} from "@/types/dtoTypes";
import TRightSidePanel from "@/components/lib/panel/TRightSidePanel.vue";
import ChatDetailPanel from "@/components/chat/ChatDetailPanel.vue";
import ChatAnswerReportPanel from "@/components/chat/ChatAnswerReportPanel.vue";


@Component({
  computed: {
    Actor() {
      return Actor
    }
  },
  components: {ChatAnswerReportPanel, ChatDetailPanel, TRightSidePanel, UserAvatar, ChatMessageCard}
})
export default class ChatPanel extends Vue {
  @Prop() private chatId!: string;

  @Ref() readonly messageEditor!: HTMLTextAreaElement
  @Ref() readonly messagesScrollWrapper!: HTMLElement

  private chat: Chat | null = null;
  private messages: ResourceCollection<ChatMessage> | null = null
  private loading = false;
  private detailOpen = false;
  private alignLeft = true;
  private attachScenarioSection = false;
  private scrollHeight = 0;
  private scrollDownEnabled = false
  private scrollDownMinOffset = -50
  private hasUnreadMessage = false
  private replyTo: ChatMessage | null = null;

  private messageText: string | null = null
  private messageIsSending = false

  private subscription: StompSubscription | null = null

  mounted() {
    this.init()
  }

  init() {
    this.replyTo = null
    this.attachScenarioSection = false
    this.messages = new ResourceCollection(
        ChatMessage.api()
            .filter({
              chat: this.chatId
            })
            .with(['parent', 'attachment', 'employee'])
            .page(1)
            .perPage(15),
        'infinite-chat-' + this.chatId,
        true
    )
    // reset page number
    if (this.messages && this.messages.pagination) {
      this.messages.pagination.current_page = 1
    }

    try {
      this.chat = Chat.fromId(this.chatId);
      //console.log("[ ChatPanel#mounted ] id=" + this.chatId + " fromId: " + this.chat)
    }
    catch (e) {
      //console.error("[ ChatPanel#mounted ] id=" + this.chatId + " not found in store: ")
    }

    if (!this.chat) {
      //console.log("[ ChatPanel#mounted ] request by id=" + this.chatId)
      Chat.api()
          .with(['channel', 'channelUser', 'latestChatMessage', 'bookmark', 'conversation', 'channelUserPlans'])
          .find(this.chatId)
          .then(chat => {
            //console.log("[ ChatPanel#mounted ] id=" + this.chatId + ", response: ", chat)
            this.chat = chat;
            this.loadMessagesAndSubscribe()
            //this.loadChannelUserPlans()
            if (this.chat.channelUser) {
              this.chat.channelUser.load('profile').then(() => {
                //this.loadBookClient()
                this.setTitle(chat)
              })
            }
            else {
              this.setTitle(chat)
            }
          })
    }
    else {
      this.setTitle(this.chat)
      this.loadMessagesAndSubscribe()
    }
  }

  setTitle(chat: Chat) {
    document.title = `${chat.preferName} [ChatPlant]`
  }

  syncProfile(profile: Profile): Promise<Profile> {
    profile.syncStatus = ProfileSyncStatus.TO_SYNC;
    return profile.save()
  }

  syncAndReloadProfile(profile: Profile) {
    this.syncProfile(profile).then((resp) => {
      console.log('sync profile result: ', resp)
    })
  }

  get channelUserPlan(): ChannelUserPlan | null {
    if (this.chat && this.chat.channelUserPlans?.length) {
      return this.chat.channelUserPlans[0]
    }
    return null
  }

  @Watch("chatId")
  onChatIdChange() {
    //console.log("[ ChatPanel#onChatIdChange ] to id=" + this.chatId)
    //console.log("[ ChatPanel#onChatIdChange ] message filter: " , this.messages?.filter)
    this.unsubscribe()
    this.init()
  }

  get isSendMessageAvailable(): boolean {
    if (this.chat && this.chat.channel) {
      return !!this.chat.channel.registered
    }
    return false
  }

  toggleBookmark(chat: Chat) {
    if (chat.isBookmarked) {
      Chat.removeBookmark(chat).then(() => {
        Chat.api()
            .with(['bookmark'])
            .find(chat.id);
        this.$root.$emit('bookmarkDeleted', chat)
      })

    }
    else {
      Chat.createBookmark(chat).then(() => {
        Chat.api()
            //.with(['channel', 'channelUser', 'latestChatMessage', 'bookmark', 'conversation'])
            .with(['bookmark'])
            .find(chat.id)
        this.$root.$emit('bookmarkDeleted', chat)
      })
    }
  }

  removeConversation(chat: Chat) {
    if (chat.isConversated) {
      Chat.removeConversationMark(chat).then(() => {
        Chat.api()
            .with(['conversation'])
            .find(chat.id);
        this.$root.$emit('conversationDeleted', chat)
      })
    }
  }

  sendMessage() {
    if (this.chat && this.messageText && this.messageText.trim().length > 0 && this.isSendMessageAvailable && !this.messageIsSending) {
      this.messageIsSending = true
      const message: TextMessageDto = { text: this.messageText.trim() }
      if (this.replyTo) {
        message.replyTo = this.replyTo.messageId
      }
      Chat
          .sendMessage(this.chat, message)
          .then(() => {
            //console.log('resp =>', response);
            this.messageText = null
            this.replyTo = null
            //this.scrollDown()
          })
          .finally(() => this.messageIsSending = false)
    }
  }


  loadMessagesAndSubscribe() {
    this.$emit('chatOpen', this.chat)
    if (this.messages) {
      this.messages.updateItems()
      //console.log('[ ChatMessages#mount ] socket:', this.socket, 'items?', this.messages.items.length)
      // if (!this.messages.items.length) {
      this.loadData()
      // }
      this.subscribe()
    }
  }
  beforeDestroy() {
    //console.log('[ ChatMessages#beforeDestroy ]')
    this.unsubscribe()
  }
  subscribe() {
    if (!this.chat) return
    const { id: chatId } = this.chat;
    this.subscription = this.$stompClient.$subscribe({
      topicName: `/chat/${chatId}`,
      subscriptionName: `chat-${chatId}`,
      callback: (data) => this.onEventReceive(chatId, data)
    })
    //console.log('[ ChatMessages#mount ] subscribed to:', `/chat/${chatId}`)
  }

  unsubscribe() {
    if (this.subscription) {
      //console.log('[ ChatMessages#unsubscribe ] from: ', this.subscription)
      this.$stompClient.$unsubscribe(this.subscription)
    }
  }

  onEventReceive(chatId: string, data: any) {
    //console.log('socket event:', chatId, data)
    if (data && data.chatId === chatId && data.id) {
      ChatMessage.api().with(['parent', 'attachment', 'employee']).find(data.id).then(message => {
        //console.log('load new? ', message)
        //this.messages?.syncWithStore()
        this.messages?.appendToCollection(message)
        if (this.scrollDownEnabled) {
          this.hasUnreadMessage = true
        }
      })
    }
  }

  onDetailPanelClose(panelState: boolean) {
    this.detailOpen = panelState
  }

  loadData() {
    if (this.messages) {
      this.loading = true
      this.messages.requestItems().finally(() => this.loading = false);
    }
  }

  get sortedItems(): ChatMessage[] {
    if (this.messages) {
      return this.messages.items.sort(byPropertiesOf<ChatMessage>(['modifiedDate']))
    }
    return []
  }
  handleScroll(event: any) {
    this.scrollDownEnabled = this.messagesScrollWrapper?.scrollTop < this.scrollDownMinOffset

    if (this.hasUnreadMessage && this.messagesScrollWrapper?.scrollTop > -50) {
      this.hasUnreadMessage = false
    }

    const { currentTarget } = event
    const { scrollTop, offsetHeight, scrollHeight } = event.currentTarget
    if (!this.loading) {
      this.scrollHeight = scrollHeight
    }
    const currentTop = scrollHeight - (offsetHeight + scrollTop * -1);
    if (currentTop < 5 && !this.loading && this.scrollHeight === scrollHeight && this.messages && !this.messages.$isLastPage) {
      this.loading = true
      this.messages.nextPage().finally(() => {
        //console.log("loading done")
        currentTarget.scrollTo(0, scrollTop)
        this.loading = false;
        this.scrollHeight = scrollHeight
      })
    }
  }

  scrollDown() {
    this.messagesScrollWrapper.scrollTo({ top: 0, behavior: 'smooth' } )
  }


  loadNextPage() {
    if (this.messages) {
      this.messages.nextPage().then(() => {
        if (this.messages && this.messages.pagination) {
          const storedItems = this.messages.items.length
          const {size, current_page} = this.messages.pagination
          if (size && size * current_page < storedItems) {
            //console.log('load next again...', size * current_page, storedItems)
            this.loadNextPage()
          }
        }
      })
    }
  }

  clearResponse() {
    this.messageText = null;
  }

  appendEmoji(emoji: string) {
    if (!this.messageText) {
      this.messageText = ''
    }
    this.messageText += emoji + ' '
  }

  toggleDetail() {
    this.detailOpen = !this.detailOpen
  }
  toggleAlign() {
    this.alignLeft = !this.alignLeft
  }

  get tgUserFullName() {
    if (this.chat?.channelUser?.user) {
      const { firstName, lastName } = this.chat.channelUser.user
      return [firstName, lastName].join(' ').trim()
    }
    return undefined
  }

  get profileFio() {
    if (this.chat?.channelUser?.profile) {
      const { lastName, firstName, middleName} = this.chat.channelUser.profile
      if (!lastName && !firstName && !middleName) {
        return 'не указано'
      }
      return [lastName, firstName, middleName].join(' ').trim()
    }
    return undefined
  }

  get clinic() {
    return this.chat?.channelUser?.profile?.clinic?.name
  }

  toggleAttachScenario() {
    this.attachScenarioSection = !this.attachScenarioSection
  }

  setReplyMessage(message: ChatMessage) {
    this.replyTo = message;
    this.focusToMessageEditor()
  }

  focusToMessageEditor() {
    this.$nextTick(() => this.messageEditor.focus())
  }
  resetReply() {
    this.replyTo = null;
  }

  supressChat() {
    if (this.chat?.attention) {
      this.chat.attention = false;
      this.chat.save()
    }
  }
}
